/* Added breakpoint as globals are two large */
$standard_mobile: max-width 480px;

@mixin mapcontainer-searchbox {
  width: 32%;
  font-size: inherit;
  margin-#{$rdirection}: 2px;
  float: $ldirection;
  clear: none;
}

#map-foreground-node {
  padding: 0px;
  z-index: 100000;
}

#map-overlay-background {
  background-color: white;
  opacity: 1;
}

.click_and_collect_map {
  width: 100%;
  position: fixed;
  top: 0px;
  .close-container {
    display: none;
  }
  .overlay-content {
    height: 100%;
    width: 100%;
    position: relative;
    .map-container {
      height: 100%;
      position: relative;
      overflow: hidden;
      .address-map-search-panel {
        position: absolute;
        top: 0px;
        z-index: 1;
        margin-left: 15px;
        margin-top: 15px;
        border-top: 0px;
        font-size: 1.4em;
        width: 450px;
        .status-bar-wrapper {
          position: fixed;
          bottom: 55%;
          width: 100%;
          @include breakpoint($standard_mobile) {
            left: 0px;
          }
          .status-bar {
            margin: 0 auto;
            width: 20em;
            background: url(/media/export/cms/click_and_collect/loading.gif) no-repeat white center 20px;
            padding-top: 100px;
            min-height: 175px;
            border: 1px solid #000;
            .close {
              float: right;
              position: relative;
              top: -102px;
              a {
                text-decoration: none;
                color: inherit;
              }
            }
            .message {
              border-top: 0px;
              padding: 0.5em;
              font-family: 'Brandon Text', arial, verdana;
              font-size: 13px;
              letter-spacing: 0.06em;
              text-align: center;
            }
            .message:first-child {
              border-radius: 0px;
            }
            /*
            .message.error {
              background: #fcc;
              border-color: #a66;
            }
            .message.warning {
              background: #ffc;
              border-color: #aa6;
            }
            .message.success {
              background: #cfc;
              border-color: #6a6;
            }
            .message.information {
              background: #bdf;
              border-color: #68a;
            } */
          }
          @media (max-width: 480px) {
            .status-bar {
              width: 100%;
            }
          }
        }
        .search-box-field {
          float: left;
          width: 400px;
          padding: 2px 5px;
          font-size: 14px;
          margin: 0;
          height: 24px;
          font-family: 'Brandon Text', arial, verdana;
          @include breakpoint($small-down) {
            width: 230px;
            margin-top: 25px;
          }
        }
        .search-box-submit {
          width: 30px !important;
          padding: 6px;
          height: 30px;
          text-align: center;
          cursor: pointer;
          margin-left: 0px;
          background: url(../../../../../media/export/images/click_collect/map_search.jpg) no-repeat center center;
          @include mapcontainer-searchbox;
          .loading {
            span:before {
              content: '';
            }
          }
          @include breakpoint($small-down) {
            margin-top: 25px;
          }
        }
        .search-box-toggle {
          width: 50px;
          padding: 6px;
          margin-left: 0px;
          height: 30px;
          width: 30px;
          text-align: center;
          cursor: pointer;
          @include mapcontainer-searchbox;
          .loading {
            span:before {
              content: '';
            }
          }
        }
        .search-box-geolocate {
          width: 50px;
          .loading {
            span:before {
              content: '';
            }
          }
        }
        .search-box-cancel {
          width: 50px;
          @include mapcontainer-searchbox;
          .loading {
            span:before {
              content: '';
            }
          }
        }
        .search-box {
          overflow: hidden;
          label {
            display: block;
            font-size: inherit;
            margin: 0 0 1em;
            font-weight: bold;
          }
        }
        .search-box-geolocate {
          @include mapcontainer-searchbox;
        }
      }
      .search-box-cancel {
        padding: 6px;
        height: 30px;
        width: 30px;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1;
        background: #fff url(../../../../../media/export/images/click_collect/map_close.png) no-repeat center center;
      }
      .search-box-geolocate {
        position: absolute;
        width: 49px;
        height: 50px;
        right: 0.5em;
        bottom: 5%;
        @include breakpoint($small-down) {
          bottom: 10%;
        }
        z-index: 1;
        overflow: hidden;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        color: black;
        text-align: center;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        background: #737272;
        cursor: pointer;
        padding: 10px;
        background: #000 url(../../../../../media/export/images/click_collect/map_geolocate.jpg) no-repeat center center;
      }
      .address-map-directions-panel {
        header {
          width: 288px;
          padding-top: 10px;
          height: 57px;
          background-color: white;
          float: left;
          @include breakpoint($small-down) {
            width: 100%;
          }
          h2 {
            font-size: 15px;
            padding-left: 0.5em;
            text-transform: uppercase;
            letter-spacing: 0.24em;
            margin-right: 4px;
            font-family: 'Brandon Text Bold';
            margin-bottom: 5px;
          }
          a.close {
            span:before {
              display: block;
              font-family: 'icomoon';
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-size: 16px;
              color: #fff;
              text-decoration: none;
            }
          }
          .button {
            margin-left: 10px;
            padding: 0 3px 0 3px;
            font-size: 10px;
            border: 0;
            line-height: 23px;
            background: none;
            .select-access-point_dir {
              color: #ff4760;
              text-align: left;
              width: auto;
              font-size: 14px;
              letter-spacing: 0.24em;
              padding: 0px;
              margin-left: -3px;
              border-bottom: 2px solid #ff4760;
              display: inline-block;
              line-height: 18px;
              font-family: 'Brandon Text Bold', arial, verdana;
            }
          }
        }
        a.close {
          display: block;
          position: absolute;
          right: 11px;
          top: 0px;
          text-decoration: none;
          z-index: 3;
          margin: 0;
          width: 30px;
          height: 57px;
          text-indent: 100%;
          color: transparent;
          background: url(../../../../../media/export/images/click_collect/map_close.jpg) no-repeat -5px 8px;
          @include breakpoint($small-down) {
            right: 0px;
          }
          span {
            display: none;
          }
        }
        .directions {
          float: left;
          height: 95.5%;
          .adp {
            font-size: 11px;
            font-family: Arial, sans-serif;
            margin-bottom: 50px;
          }
          #adp-placemark {
            font-size: 11px;
            font-family: Arial, sans-serif;
          }
          .adp-placemark {
            font-size: 13px;
            background-color: white;
            font-family: Arial, sans-serif;
            td {
              padding: 2px 0px 2px 15px;
              color: black;
              font-family: 'Brandon Text', Arial, sans-serif;
            }
            td:first-child {
              width: 50px;
            }
          }
          .adp-text {
            font-size: 13px;
            letter-spacing: 0.06em;
            font-family: 'Brandon Text', arial, verdana;
          }
          .adp-summary {
            font-size: 13px;
            letter-spacing: 0.06em;
            font-family: 'Brandon Text', Arial, sans-serif;
            span:nth-child(1) {
              text-align: right;
              padding-right: 1px;
              float: right;
            }
            span:nth-child(3) {
              text-transform: capitalize;
              word-wrap: break-word;
              padding-left: 50px;
            }
            span:nth-child(2) {
              display: none;
            }
          }
          .adp-step {
            padding: 0.3em 3px;
            line-height: 1.26em;
            font-size: 11px;
            font-family: Arial, sans-serif;
          }
          .adp-substep {
            padding: 0.3em 3px;
            line-height: 1.26em;
            font-size: 13px;
            letter-spacing: 0.06em;
            font-family: 'Brandon Text', Arial, sans-serif;
          }
          .adp-directions {
            tr {
              td {
                .adp-distance {
                  padding-right: 10px;
                }
                &:nth-child(1) {
                  width: 10%;
                }
                &:nth-child(2) {
                  width: 10%;
                }
                &:nth-child(3) {
                  width: 62%;
                  word-wrap: break-word;
                }
              }
            }
          }
        }
        .locations {
          height: 93.5%;
          overflow-y: scroll;
          background-color: white;
          font-family: roboto, arial, verdana;
        }
        .location {
          cursor: pointer;
          overflow: hidden;
          padding: 1em 0.5em;
          margin: 0;
          border-bottom: 1px solid #ccc;
          -webkit-transition: padding 0.5s, background 0.5s;
          transition: padding 0.5s, background 0.5s;
          p {
            margin: 0 0 0.5em;
          }
          .image {
            float: left;
            width: 100px;
            margin: 0 1em 0 0;
            img {
              width: 100%;
            }
          }
          .distance {
            float: right;
            color: #999;
            .uom {
              text-transform: lowercase;
            }
          }
          .location_description {
            font-style: italic;
          }
          .title {
            span {
              font-weight: bold;
              font-size: 15px;
              border: 0;
              padding: 0.5em 0;
              margin-bottom: 10px;
            }
          }
          .button {
            clear: both;
            margin-top: 1em;
            border: none;
            background: none;
            width: 100%;
            padding: 0px;
          }
        }
      }
      .address-map-location-panel {
        display: none;
        header {
          width: 288px;
          padding-top: 10px;
          height: 40px;
          background-color: white;
          h2 {
            font-size: 15px;
            padding-left: 0.5em;
            font-weight: bold;
            letter-spacing: 0.24em;
            text-transform: uppercase;
          }
          @include breakpoint($small-down) {
            width: 100%;
            padding-top: 6px;
          }
        }
        a.close {
          display: block;
          position: absolute;
          right: -18px;
          top: 0px;
          text-decoration: none;
          z-index: 3;
          margin: 0;
          background: none;
          width: 39px;
          height: 40px;
          text-indent: 100%;
          background: #fff url(../../../../../media/export/images/click_collect/map_panel_close.jpg) no-repeat 17px -7px;
          span {
            display: none;
          }
          @include breakpoint($small-down) {
            background: #ff4760 url(../../../../../media/export/images/click_collect/map_mobile_panel_close.jpg)
              no-repeat 4px -3px;
            right: 0px;
            width: 30px;
          }
        }
        .locations {
          height: 100%;
          overflow-y: scroll;
          background-color: white;
          font-family: roboto, arial, verdana;
          .messages {
            background: none;
            border: 0px none;
            padding: 10px;
            .no-locations-found {
              margin: 5px;
              font-size: 14px;
              letter-spacing: 0.06em;
              font-family: 'Brandon Text', arial, verdana;
            }
            .no-nearby-locations {
              cursor: pointer;
            }
          }
        }
        .location {
          cursor: pointer;
          overflow: hidden;
          padding: 1em 0.5em;
          margin: 0;
          border-bottom: 1px solid #ccc;
          -webkit-transition: padding 0.5s, background 0.5s;
          transition: padding 0.5s, background 0.5s;
          &.selected {
            background: #e8e6e6;
          }
          &:hover {
            background: #e8e6e6;
          }
          &:last-child {
            margin-bottom: 50px;
          }
          p {
            margin: 0 0 0.5em;
            font-size: 13px;
            letter-spacing: 0.1em;
          }
          .image {
            float: left;
            width: 100px;
            margin: 0 1em 0 0;
            img {
              width: 100%;
            }
          }
          .distance {
            float: right;
            color: #898989;
            letter-spacing: 0.24em;
            p {
              font-size: 11px;
              .uom {
                text-transform: lowercase;
              }
            }
          }
          .location_description {
            p {
              font-weight: bold;
              letter-spacing: 0.06em;
              font-style: italic;
            }
          }
          .address {
            p {
              font-size: 14px;
            }
          }
          .opening_hours {
            p {
              font-size: 14px;
            }
          }
          .title {
            span {
              font-weight: bold;
              font-size: 15px;
              border: 0;
              padding: 0.5em 0;
              margin-bottom: 10px;
              font-family: 'Brandon Text Bold';
            }
            .distance {
              display: none;
            }
          }
          .button {
            clear: both;
            margin-top: 5px;
            border: none;
            background: none;
            width: 100%;
            padding: 0px;
            a {
              color: #ff4760;
              text-align: left;
              width: auto;
              font-size: 14px;
              letter-spacing: 0.24em;
              padding: 4px 0px;
              border-bottom: 2px solid #ff4760;
              display: inline-block;
              font-family: 'Brandon Text Bold';
            }
          }
          .select-access-point {
            margin-right: 10px;
          }
          .location_more_info {
            display: none;
          }
        }
        .map {
          .location {
            min-width: 200px;
          }
        }
        p {
          margin: 0 0 0.5em;
        }
        .button {
          overflow: hidden;
          a {
            background: #2b2b2b;
            color: #fff;
            cursor: pointer;
            font-family: inherit;
            font-size: 11px;
            font-weight: bold;
            margin-bottom: 3px;
            padding: 2px 5px;
            text-align: left;
            text-transform: uppercase;
            white-space: nowrap;
            letter-spacing: 0px;
            float: left;
            border: 0px none;
          }
        }
      }
      .active {
        a.close {
          background: #000 url(../../../../../media/export/images/click_collect/map_close.jpg) no-repeat -5px 0px;
          right: 11px;
          width: 30px;
          @include breakpoint($small-down) {
            right: 0px;
          }
        }
      }
      .address-map-location-panel.active {
        a.close {
          span:before {
            content: attr(data-active);
          }
        }
        @include breakpoint($small-down) {
          top: 0px;
          height: auto;
          padding-right: 0px;
        }
      }
      .map {
        height: 100%;
        width: 100%;
      }
      .map-info-window {
        border: 1px solid #999;
        padding: 10px;
        background: #fff;
        .show-access-point {
          display: none;
        }
        .title {
          .distance {
            display: none;
          }
        }
        .location {
          width: 238px;
          padding: 0 5px;
          .title {
            padding-bottom: 5px;
            font-weight: bold;
            font-size: 15px;
            letter-spacing: 0.1em;
            font-family: 'Brandon Text Bold';
          }
          .distance {
            p {
              font-size: 12px;
              color: #898989;
              letter-spacing: 0.06em;
              font-family: 'Brandon Text';
              text-transform: lowercase;
            }
          }
          .address {
            p {
              font-size: 14px;
              letter-spacing: 0.1em;
              font-family: 'Brandon Text';
            }
          }
          .location_description {
            p {
              font-weight: bold;
              letter-spacing: 0.06em;
              font-style: italic;
            }
          }
          .opening_hours {
            p {
              font-size: 14px;
              letter-spacing: 0.06em;
              font-family: 'Brandon Text';
            }
          }
          .button {
            clear: both;
            border: none;
            background: none;
            width: 100%;
            padding: 0px;
            a.select-access-point {
              margin-bottom: 10px;
            }
          }
          .image {
            img {
              max-width: 100% !important;
            }
            @include breakpoint($small-down) {
              display: none;
            }
          }
          .location_more_info {
            cursor: pointer;
            text-decoration: underline;
            display: none;
            @include breakpoint($small-down) {
              display: block;
              margin-bottom: 10px;
            }
          }
          .opening_hours {
            @include breakpoint($small-down) {
              display: none;
            }
          }
          p {
            margin-bottom: 8px;
          }
        }
        .button {
          overflow: hidden;
          a {
            color: #ff4760;
            text-align: left;
            width: auto !important;
            font-size: 14px;
            letter-spacing: 0.24em;
            padding: 4px 0px !important;
            border-bottom: 2px solid #ff4760 !important;
            display: inline-block;
            float: left;
            font-family: 'Brandon Text Bold';
          }
        }
      }
      .directions {
        background: white;
        color: #fff;
        overflow-y: scroll;
        max-height: 100%;
        .button {
        }
      }
      .address-map-directions-panel {
        top: 60px;
        bottom: 0px;
        display: block;
        position: absolute;
        left: -300px;
        width: 300px;
        padding-right: 24px;
        z-index: 2;
        -webkit-transition: left 0.5s;
        transition: left 0.5s;
        @include breakpoint($small-down) {
          top: 0px;
          left: 0px;
          width: 100%;
          overflow-y: auto;
          padding-right: 0px;
        }
      }
      .address-map-location-panel {
        top: 60px;
        bottom: 0px;
        display: block;
        position: absolute;
        left: -300px;
        width: 300px;
        padding-right: 24px;
        z-index: 2;
        -webkit-transition: left 0.5s;
        transition: left 0.5s;
        @include breakpoint($small-down) {
          top: auto;
          left: 0px;
          bottom: 0px;
          width: 100%;
          height: 30px;
          margin: 0;
          padding-bottom: 0;
          overflow: hidden;
        }
      }
      .address-map-directions-panel,
      .address-map-location-panel.active {
        left: 0;
      }
    }
    .search-box-cancel {
      span:before {
        padding-top: 5px;
      }
    }
    .search-box-geolocate {
      span:before {
        font-size: 25px;
        line-height: 25px;
        padding: 2px 0px 2.5px 3.5px;
      }
    }
  }
}

html.mac {
  .click_and_collect_map {
    .overlay-content {
      .map-container {
        .address-map-directions-panel {
          header {
            height: 60px !important;
          }
        }
        .address-map-location-panel {
          header {
            height: 40px !important;
          }
        }
      }
    }
  }
}

.click_and_collect_map {
  .overlay-content {
    .map-container {
      .search-box-toggle {
        position: absolute;
        width: 49px;
        height: 50px;
        right: 0.5em;
        bottom: 15%;
        @include breakpoint($small-down) {
          bottom: 20%;
        }
        z-index: 1;
        overflow: hidden;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        color: black;
        text-align: center;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 10px;
      }
    }
  }
}

.click_and_collect_map.local-collection {
  .overlay-content {
    .map-container {
      .search-box-toggle {
        background: url(/media/export/cms/click_and_collect/toggle-bb-47x47.png) no-repeat center center;
      }
    }
  }
}

.click_and_collect_map.store-collection {
  .overlay-content {
    .map-container {
      .search-box-toggle {
        background: url(/media/export/cms/click_and_collect/toggle-ups-47x47.png) no-repeat center center;
      }
    }
  }
}

.local-collection-map {
  height: 100%;
  ifrmae {
    border: 0;
    width: 100%;
    height: 97%;
  }
}

.set-collection-point {
  overflow: hidden;
  padding: 20px 0px;
  border-top: 1px dotted #ccc;
  margin: 20px 0px;
  border-bottom: 1px dotted #ccc;
  .form-item {
    margin: 3% 5% 1% 0% !important;
    width: 100% !important;
    img {
      margin-bottom: -15px;
    }
    .local-collection-button,
    .store-collection-button {
      font-size: 12px;
      background: #ff4661;
      margin-top: 20px;
    }
  }
}

.show-collection-point {
  overflow: hidden;
  padding: 20px 0px 5px 0px;
  border-top: 1px dotted #ccc;
  margin: 20px 0px 0px 0px;
  border-bottom: 1px dotted #ccc;
  h3 {
    a {
      font-family: arial, verdana, helvetica;
      text-decoration: underline !important;
      margin-left: 15px;
      font-weight: normal;
      font-variant: normal;
      font-size: 14px;
      &:hover {
        text-decoration: none !important;
      }
    }
  }
}

.address-to-use {
  margin-bottom: 15px;
  label {
    float: left;
    @include breakpoint($standard_mobile) {
      float: none;
    }
    margin-right: 25px;
    text-transform: uppercase;
    @include breakpoint($small-down) {
      text-transform: none;
    }
    &:before {
      margin-top: -4px;
    }
  }
}

@include breakpoint($medium-down) {
  .viewcart {
    .shipmethod-panel {
      .content {
        padding-right: 0px;
        text-align: right;
        .ship-method-group-label {
          letter-spacing: 0.1em;
          margin-left: 0px;
          padding-left: 0px;
          width: auto;
          &.more_info_label {
            padding-left: 0px;
          }
          a.more-info {
            margin-left: 0px !important;
          }
        }
      }
    }
  }
  .viewcart-buttons-panel {
    padding-left: 10px;
    .continue-shopping {
      margin-top: 0px;
      vertical-align: top;
      letter-spacing: 0.1em;
    }
    a.continue-checkout {
      padding: 10px;
    }
    .paypal-checkout {
      width: 125px;
      margin-top: 0;
      vertical-align: top;
    }
    span.or {
      margin: 0 2px !important;
    }
  }
}

@include breakpoint($small-down) {
  .viewcart {
    .shipmethod-panel {
      .content {
        padding-right: 0px;
        text-align: left;
        .ship-method-group-label {
          margin-left: 0px;
          padding-left: 0px;
          width: 100%;
        }
      }
    }
  }
  .viewcart-buttons-panel {
    .continue-shopping {
      margin-top: 0px;
      vertical-align: top;
      letter-spacing: 0.2em;
    }
    .paypal-checkout {
      width: 115px;
      margin-top: 0;
      vertical-align: top;
    }
    span.or {
      margin: 0 10px;
      line-height: 33px;
    }
  }
}

@include breakpoint($medium-down) {
  .viewcart-buttons-panel {
    .paypal-checkout {
      margin-top: 0;
      vertical-align: top;
      span.paypal-message {
        width: 109px;
        margin: 0px auto;
      }
    }
    .continue-shopping {
      padding: 5px;
      margin-top: 0px;
      vertical-align: top;
    }
  }
  .left {
    .ship-method-group-label {
      a.more-info {
        margin-left: 0px;
      }
    }
    .more_info_label {
      width: 57%;
      margin-left: 21px;
    }
    .minimise {
      width: 30%;
      margin-left: 25px;
    }
  }
  #checkout_shipmethod {
    .content {
      padding-right: 0px;
      text-align: right;
      .ship-method-group-label {
        letter-spacing: 0.5px;
        font-size: 13px;
      }
      &.minimise {
        border: 1px solid red;
        width: auto;
      }
      &.more_info_label {
        border: 1px solid red;
        width: auto;
      }
    }
  }
  .set-collection-point {
    .local-collection-button {
      width: 230px;
    }
  }
  .new-address-container {
    #qas {
      overflow: hidden;
    }
  }
}

.new-address-container {
  #qas {
    overflow: hidden;
  }
}
