/**
 * @file uk_pc.scss
 *
 * \brief Pull all parts of the US-PC theme together into one file
 */

// Virtual Appointment Enabling variable
$virtual-appointment: true !default;

@import 'overrides/overrides';
@import '../../bb_base/scss/bobbibrown-base';
@import 'global/mixin';
// SMACSS/Components/account

@import '../../bb_base/scss/components/chat/prechat';
@import '../../bb_base/scss/components/chat/vba';
@import 'global/variable';
// MPP styles
@import 'sections/products/mpp';
@import 'sections/account/account';

// SMACSS/Components/checkout
@import 'sections/checkout/checkout';
@import 'components/engraving';

//boutiques
@import 'sections/boutiques/boutiques';

// SMACSS/store_locator
@import 'components/store_locator';

//Phone Order
@import 'components/phone_order';

// Click and Collect
@import 'sections/checkout/clickcollect';

// Sample Grid
@import 'components/products/sample_grid';

//Email Sigm Up UK Overrides
@import 'components/email-sms-signup';
@import 'components/email-sms-thank-you';

//Build Your Brow Kit
@import 'sections/landing_pages/bybk';

//Black Friday Sign Up Page
@import 'sections/landing_pages/bf_signup';

//Promotions Pages
@import 'sections/landing_pages/promotions_signup';

//Privacy Page
@import 'components/bt/bt';

// One Trust Cookie settings
@import 'components/cookie_setting_ot';

// GDPR update
@import 'components/_social_login';

// Book Appointment styles.
@import 'components/appointment_booking';

/*Header*/
.site-header__addtobag-cell {
  width: 30% !important;
  &.site-header__addtobag-cell--product-info {
    width: 70% !important;
  }
}
/* Search */

.section-esearch {
  #search-wrapper {
    #col-1,
    #summary,
    .results-summary,
    .results-header {
      display: block;
    }
  }
  #results-container {
    #col-1 {
      float: left;
      width: 20%;
    }
    #col-2 {
      float: right;
      width: 80%;
      #product-results {
        .results-header {
          .product-sort-container {
            display: none;
          }
        }
      }
      #featured-products {
        float: left;
        width: 100%;
      }
    }
  }
  #bestseller-results {
    .results-header {
      .product-sort-container {
        display: none;
      }
    }
  }
}

.summary-search-form,
.summary-chat-now {
  display: none;
}

.section-esearch {
  #search-wrapper {
    #results-container {
      #col-2 {
        #product-results {
          margin-left: 30px;
        }
      }
      #col-1 {
        #breadcrumbs,
        #dimensions {
          margin-bottom: 20px;
        }
        #dimensions,
        #breadcrumbs,
        #recent-searches {
          h3 {
            text-transform: uppercase;
            padding: 10px 0px 10px 10px;
            font-size: 1em;
            margin-bottom: 20px;
            border: 1px solid #bfbfbf;
          }
          dl {
            margin-left: 10px;
            dd {
              margin-left: 10px;
              padding-bottom: 3px;
              .close {
                margin-left: 5px;
              }
            }
            dt {
              cursor: pointer;
              margin-bottom: 5px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}

#bt_notification {
  div.bt-content {
    border-color: #ccc !important;
    border-width: 1px !important;
    a {
      text-decoration: none !important;
      color: #ff4661 !important;
    }
    .bt-find-more {
      line-height: 2em;
      font-size: 14px;
      border-bottom: 1px solid #ff4661;
    }
    h6 {
      font-size: 1.17em;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .bt-pp-section {
      margin-bottom: 20px;
    }
    .button {
      margin-top: 10px;
      display: block;
      color: #fff !important;
    }
  }
  div.bt-bottom-right {
    width: 320px;
    bottom: 10px;
  }
}

#email_friend_modal {
  .modal-wrapper {
    h2 {
      margin-bottom: 10px;
    }
    #email_a_friend {
      .xgrid {
        label {
          font-size: 10px;
        }
        input[type='text'] {
          height: auto;
          line-height: 10px;
          padding: 2px;
          font-size: 10px;
        }
        textarea {
          line-height: 10px;
          font-size: 10px;
        }
        .disclaimer {
          margin: 0;
          line-height: 10px;
        }
        .twelve {
          font-size: 10px;
          label {
            line-height: 10px;
          }
        }
        .row {
          line-height: 10px;
        }
      }
    }
  }
}

@import 'sections/pc/all';

.esearch_results--pc {
  .product-thumb {
    &__image {
      height: 315px;
    }
  }
}

.tiny-waitlist-overlay {
  &.bis-signup {
    .waitlist_header {
      font-size: 25px;
      line-height: 32px;
    }
    p.waitlist_message {
      font-size: 16px;
    }
    .field {
      .align-r {
        position: unset;
        > .input-btn {
          margin: 20px 0;
        }
      }
    }
    .email_input {
      input[type='text'] {
        margin-bottom: 0;
      }
    }
  }
  .email_label {
    margin-bottom: 5px;
  }
  .email_input {
    display: inline-block;
    input[type='text'] {
      width: 300px;
      margin-bottom: 10px;
    }
  }
  .waitlist-header {
    display: block;
    font-weight: bold;
    padding-bottom: 10px;
  }
  h2 {
    margin: 0 0 15px 0;
    text-align: left;
  }
  p.waitlist_message {
    margin-bottom: 10px;
  }
  .error_messages.error li {
    margin-left: 0px;
  }
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: inline-block;
    }
  }
}

.waitlist_thankyou_message {
  font-family: $brandon-text-bold;
  margin: 90px 60px;
  line-height: 1.45em;
}

.waitlist-iframe-wrapper {
  height: 95%;
  width: 100%;
}

.offerspick {
  padding: 20px 0;
  min-height: auto;
}
/* power review scss */

.product-full__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        text-transform: initial;
      }
    }
  }
}

#power_review_container {
  .p-w-r {
    .form-group {
      input {
        font-family: $brandon-text-medium;
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review {
      .pr-rd-description {
        width: 75%;
        p.pr-rd-description-text {
          width: 80%;
        }
        .pr-rd-author-nickname {
          font-size: 14px;
          font-family: $brandon-text-bold;
        }
      }
    }
    #pr-image-display {
      display: none;
    }
    .pr-review-display {
      .pr-review {
        .pr-rd-side-content-block {
          &.pr-rd-right {
            top: 0 !important;
          }
        }
      }
      .pr-rd-sort-group {
        float: right !important;
      }
    }
  }
}

.popup-offer {
  &__submit-wrap {
    position: relative;
  }
  &__submit-input {
    position: absolute;
    top: 4px;
    left: 80%;
  }
}

.spp {
  .product {
    .product__title {
      .product__flag {
        display: inline-block;
      }
    }
  }
}

//Loyalty
@import 'sections/loyalty';
