$mobile-width: 480px;

.promotion {
  .product_collection_hero {
    position: relative;
    background-size: cover;
    background-position: center;
    .product_collection_hero__body {
      margin-left: 12%;
      margin-top: 15%;
      position: absolute;
      width: 30.8%;
      h1.product_collection_hero__title {
        font-family: 'MillerBanner Light';
        font-size: 50px;
        letter-spacing: 0;
        line-height: 1em;
      }
      .product_collection_hero__subtitle {
        letter-spacing: 1px;
        font-size: 20px;
        line-height: normal;
      }
    }
  }
  .product_collection_hero__content {
    margin: 5% auto;
    max-width: 680px;
    @media (max-width: #{$mobile-width}) {
      margin: 5%;
    }
    p {
      margin: 0;
    }
    form.promotion_signup {
      .form-item.split-field {
        width: 45%;
        @media (max-width: #{$mobile-width}) {
          width: 100%;
        }
        &:nth-child(even) {
          float: left;
          @media (max-width: #{$mobile-width}) {
            float: none;
            margin: 5% 0;
          }
          &::after {
            clear: both;
            content: '.';
            display: block;
            visibility: hidden;
            @media (max-width: #{$mobile-width}) {
              display: none;
            }
          }
        }
        &:nth-child(odd) {
          float: right;
          @media (max-width: #{$mobile-width}) {
            float: none;
            margin: 5% 0;
          }
          &::after {
            clear: both;
            content: '.';
            display: block;
            visibility: hidden;
            @media (max-width: #{$mobile-width}) {
              display: none;
            }
          }
        }
        .field {
          width: 100%;
        }
      }
      .required {
        &.top {
          text-align: right;
          @media (max-width: #{$mobile-width}) {
            display: none;
          }
        }
        &.bottom {
          display: none;
          text-align: left;
          @media (max-width: #{$mobile-width}) {
            display: block;
          }
        }
      }
      .form-item.email-field {
        width: 100%;
        .field {
          width: 100%;
        }
      }
      .email-optin {
        margin: 3% auto;
        @media (max-width: #{$mobile-width}) {
          width: 80%;
          margin: 5% auto;
        }
        .email_signup_tickbox {
          float: left;
          max-width: 10%;
        }
        .email_signup_copy {
          float: left;
          max-width: 70%;
          @media (max-width: #{$mobile-width}) {
            max-width: 90%;
          }
        }
      }
      .form-submit {
        display: block;
        @media (max-width: #{$mobile-width}) {
          margin: 5% auto;
        }
      }
    }
    .error {
      color: #ff0000;
    }
  }
}
