///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules.
///

@mixin customer-service-header($padd) {
  float: none;
  width: auto;
  padding: .5em 20px 1.0em $padd;
  text-align: left;
  font-size: 30px;
  border-bottom: none;
}

@mixin customer-service-content($padd) {
  text-align: left;
  padding-left: $padd;
  margin-bottom: 0px;
}

@mixin address-alignment () {
  float: left;
  clear: none;
  width: 40%;
  margin: 0% 5% 5% 0%;
}
