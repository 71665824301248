.appt-book {
  .signup__mobile-phone-fieldset {
    .signup__mobile-prefix {
      width: 40%;
    }
    .signup__mobile-phone {
      padding-left: 40%;
    }
  }
}
