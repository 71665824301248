.registration,
.sign-in-page,
.welcome15-overlay {
  #loyalty_popup_offer_form {
    .welcome-overlay__checkbox {
      &__terms:checked + .label:before {
        display: none;
      }
      &.welcome-overlay__checkbox--second,
      &.welcome-overlay__checkbox--third {
        display: none;
      }
      .col-40 {
        @include breakpoint($small-only) {
          width: 100%;
        }
      }
    }
  }
  #cboxContent {
    .welcome-overlay {
      &__header {
        font-size: 40px;
      }
      &__copy {
        font-size: 17px;
      }
      &__checkbox {
        .label__content {
          font-size: 13px;
        }
      }
      &__label {
        font-size: 12px;
        margin-top: 50px;
      }
    }
  }
}

#signin {
  .sign-in-page--mobile {
    .sign-in-page__submit {
      margin-top: 20px;
    }
  }
}

//Checkout
#offer-code-panel {
  margin-top: 0px;
}

.checkout__sidebar {
  .checkout-panel {
    &--loyalty-points {
      margin: 0;
      padding: 0 20px 10px 20px;
    }
  }
}

.loyalty-points-content {
  &__summary {
    position: relative;
    &__non-member-content {
      @include breakpoint($medium-up) {
        margin: 0;
      }
      u {
        text-decoration: none;
        margin: 3px;
      }
    }
    &__member-content {
      margin: 0;
    }
    .loyalty-info-icon {
      top: 5px !important;
    }
  }
}

.profile-page__content {
  .loyalty-membership-section__header {
    @include breakpoint($medium-up) {
      padding-left: 0;
    }
  }
  .loyalty-membership-section {
    &__join-now {
      margin: 25px 20px 25px 0;
    }
  }
}

.checkout__content {
  .registration-panel {
    .create_account_content {
      .how--we-will-use-container {
        width: 90%;
      }
    }
  }
}

.checkout-panel--loyalty-join {
  #loyalty_join {
    .loyalty__section__content__options__join__link {
      background-color: $color-black;
    }
  }
}

.earning-points--marketing {
  .earning-points__data__text {
    line-height: 1;
  }
}

.earning-points__data__footer {
  max-width: 200px;
}

.marketing__wrapper {
  .marketing__benefits__header__wrapper {
    padding: 70px 15px;
    text-align: center;
    color: $color-black;
  }
}

.sign-in-page {
  .new-account__item.join_loyalty {
    label {
      display: block;
      visibility: visible;
    }
  }
}

//My account
.account-page__content {
  .account-page__non__loyalty {
    .account-page__non__loyalty__container {
      display: block;
    }
  }
  @include breakpoint($medium-up) {
    .field-item:nth-child(n + 2) {
      display: inline-block;
      width: 30%;
      margin: 0 1%;
      .earning-points__section {
        width: 100%;
      }
    }
  }
}

.loyalty__panel__offers__list-container {
  .slick-next::before,
  .slick-prev::before {
    background: transparent;
    font-size: 20px;
    padding-top: 5px;
  }
  .slick-next::before {
    left: 2px;
  }
  .slick-prev::before {
    right: 3px;
  }
}

#loyalty__content {
  #loyalty__panel__earnpoints-landing {
    .content {
      text-align: center;
    }
  }
}

@include breakpoint($medium-up) {
  #loyalty__page__index {
    .loyalty__panel__offers__list-container {
      .slick-next,
      .slick-prev {
        background: $color-dullwhite-engraved;
      }
    }
  }
  .loyalty__panel__points__info-section {
    height: 125px;
    position: relative;
    .loyalty__panel__points__info-link {
      position: absolute;
      bottom: 17px;
      left: 10px;
    }
  }
}

//loyalty banner in my account
.account-page {
  .account-page__non__loyalty__container {
    background-size: 100%;
  }
  .account-page__non__loyalty__join__now__link {
    cursor: pointer;
  }
}

.loyalty__panel__offers__offer-image-container {
  height: 320px;
  @include breakpoint($xsmall-down) {
    position: relative;
  }
}

img.loyalty__panel__offers__offer-image {
  @include breakpoint($xsmall-down) {
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    max-width: 100%;
  }
}

//Register page
.account__new-account {
  #registration_short {
    .password-field__info {
      font-size: 12px;
    }
  }
}

.account__return-user {
  input.sign-in-page__submit {
    margin-top: -30px;
  }
}

.loyalty-page__content {
  .loyalty__panel__points__info-link {
    .info-link--hyper-link {
      display: block;
    }
  }
}

@include breakpoint($medium-up) {
  .welcome15-overlay {
    .welcome-overlay {
      height: inherit;
      &__rightcontent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.account-page__non__loyalty__join__button {
  a {
    width: 100%;
    color: $color-black;
    background-color: $color-white;
  }
}

//profile preferences
.loyalty-cancel-overlay {
  #cboxContent {
    .loyalty_popover__buttons__link {
      color: $color-white;
    }
  }
}

//points history
#loyalty__page__history {
  #loyalty__panel__points-instruction {
    .loyalty__panel__points-instruction-show-more,
    .loyalty__panel__points-instruction-show-less {
      cursor: pointer;
    }
  }
}

// popup
.welcome15-overlay .welcome-overlay__checkbox .label::before {
  display: none !important;
}

// loyalty panel offers
#loyalty__panel__offers {
  .loyalty__panel__offers__title {
    font-size: 20px;
    @include breakpoint($medium-up) {
      font-size: 20px;
    }
  }
}

#loyalty__panel__offers__sku {
  .loyalty__panel__offers__sku__title {
    font-size: 15px;
    @include breakpoint($medium-up) {
      font-size: 15px;
    }
  }
  .loyalty__panel__offers__sku__subtitle {
    font-size: 16px;
  }
}

.loyalty__panel__offers__sku {
  &__list {
    &-container {
      .tabbed-rewards-block {
        &__tab {
          font-size: 12px;
          @include breakpoint($medium-up) {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.earning-points {
  &__header {
    font-size: 20px;
  }
}

#loyalty__panel__transactions {
  .loyalty__panel__transactions__title {
    font-size: 15px;
    @include breakpoint($medium-up) {
      font-size: 15px;
    }
  }
}

@include breakpoint($medium-up) {
  .node-172552 {
    .marketing__wrapper--redesign2021 {
      .loyalty__panel__points__right {
        .user_status-info_container {
          margin-top: 80px;
        }
        .user_status-points_info-link {
          margin-top: -10px;
        }
      }
    }
  }
}

@include breakpoint($medium-down) {
  .marketing__wrapper--redesign2021 {
    .loyalty__panel__points__left {
      .user_status-points_expiry {
        padding-bottom: 0;
        padding-top: 25px;
      }
    }
  }
}
