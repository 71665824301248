@supports (display: grid) {
  .samples-page {
    margin-left: 0;
    margin-right: 0;
    @include breakpoint($medium-up) {
      margin-left: 10px;
      margin-right: 10px;
    }
    .samples-panel {
      &__title,
      &__subtitle {
        text-align: center;
      }
      &__title {
        font-family: $lato-black-bold;
        font-size: 1.8em;
        letter-spacing: -1px;
        background-color: transparent;
      }
      &__subtitle {
        padding: 20px 0;
      }
      .samples {
        .remaining {
          border-top: 2px solid $color-black;
          .available,
          .max {
            margin-top: 0;
            padding: 28px 20px;
          }
          .available {
            display: none;
          }
        }
        .product-list {
          display: grid;
          grid-template-columns: 1fr;
          @include breakpoint($medium-up) {
            grid-template-columns: 1fr 1fr 1fr;
          }
          @include breakpoint($large-up) {
            grid-template-columns: repeat(4, 1fr);
          }
          grid-column-gap: 15px;
          grid-row-gap: 10px;
          float: none;
          margin-bottom: 35px;
          border-bottom: 2px solid $color-black;
          .product {
            width: auto;
            margin-left: 0;
            margin-right: 0;
            .product-img {
              text-align: center;
              img {
                width: auto;
              }
            }
            .skus {
              margin-top: 0;
              label {
                margin-top: 5px;
                padding: 5px 0;
                border-top: 2px solid $color-black;
                border-bottom: 2px solid $color-black;
              }
              .swatch,
              .shade {
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
              }
              .sample-select {
                ~ label {
                  &::before {
                    content: none;
                  }
                }
              }
              .sample-select-button {
                display: inherit;
                width: 100%;
                border: 0;
                &.selected {
                  background-color: #{$color-black};
                }
              }
            }
            &.deactivate {
              .skus {
                .sample-select-button {
                  background-color: $color-gray-light;
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
      .add-button {
        text-align: right;
        .btn-primary {
          @include button;
          width: 100%;
          height: auto;
          border: 0;
          vertical-align: middle;
          background-color: $color-pink;
          color: $color-white;
          &:hover {
            background-color: $color-pink;
            color: $color-white;
          }
          @include breakpoint($medium-up) {
            width: auto;
          }
        }
      }
    }
  }
}

@supports not (display: grid) {
  .samples {
    &-page {
      .pg_wrapper & {
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 20px;
        .max,
        .available {
          border-top: 2px solid $color-black;
          margin-top: 0;
        }
      }
      .samples-panel__title {
        background-color: transparent;
        margin-top: 0;
        text-align: center;
        border: 0 0 1px 0 solid $color-black;
      }
      .samples-panel__subtitle {
        text-align: center;
        padding: 20px 0;
      }
      .sample-select-button {
        margin-top: 0;
      }
      .product {
        position: relative;
        padding-bottom: 150px;
        @include breakpoint($medium-up) {
          &:nth-of-type(1) {
            margin-left: 2%;
          }
        }
        .details {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
        .product-img {
          float: none;
          width: 100%;
        }
      }
    }
    &-panel {
      .product {
        .sample-select-button {
          position: static;
          display: inline-block;
          margin-top: 1.2em;
          cursor: pointer;
        }
        .skus {
          margin-top: 0;
          label {
            margin-top: 5px;
            padding: 5px 0;
            border-top: 2px solid $color-black;
            border-bottom: 2px solid $color-black;
          }
          .swatch,
          .shade {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
          }
          .sample-select ~ label {
            &::before {
              content: none;
            }
          }
          .sample-select-button {
            display: inherit;
            width: 100%;
            border: 0;
            &.selected {
              background-color: $color-pink;
            }
          }
        }
        &.deactivate {
          .skus {
            .sample-select-button {
              background-color: $color-gray-light;
              pointer-events: none;
            }
          }
        }
      }
      .product.deactivate {
        .sample-select-button {
          background-color: $color-gray-light;
          &:hover {
            background-color: $color-gray-light;
          }
        }
      }
    }
    &-page &-buttons {
      .continue-checkout {
        @include breakpoint($small-down) {
          width: 100%;
        }
      }
    }
  }
  .samples-page {
    .samples-panel {
      .add-button {
        padding: 20px 0;
        border-top: 1px solid $color-black;
        text-align: right;
        .btn-primary {
          @include button;
          width: 100%;
          height: auto;
          border: 0;
          vertical-align: middle;
          background-color: $color-pink;
          color: $color-white;
          &:hover {
            background-color: $color-pink;
            color: $color-white;
          }
          @include breakpoint($medium-up) {
            width: auto;
          }
        }
      }
    }
  }
  .samples,
  .favorites,
  .past-purchases {
    &-panel {
      .skus {
        .checkout__sidebar & {
          margin: 8px 0;
          label {
            clear: none;
          }
        }
      }
      .product-name,
      .shade,
      .size {
        .checkout__sidebar & {
          font-size: 11px;
        }
      }
      .product-list {
        .checkout__sidebar & {
          overflow: hidden;
        }
      }
    }
  }
  #samples-panel .available,
  .samples-page .remaining .available,
  .samples-page .remaining .max {
    padding: 28px 20px;
  }
  .favorites,
  .past-purchases {
    &-panel {
      .details {
        .checkout__sidebar & {
          float: left;
          width: 55%;
          @include breakpoint($large-up) {
            width: 68%;
          }
        }
      }
      .shade {
        .checkout__sidebar & {
          padding-top: 3px;
        }
      }
      .product-name {
        &,
        & a {
          color: $color-black;
          text-decoration: none;
        }
      }
      input[type='submit'] {
        .checkout__sidebar & {
          &,
          &.btn {
            &:hover {
              background-color: $color-black;
            }
          }
        }
      }
    }
  }
}
