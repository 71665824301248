.store-locator.store-locator--mobile {
  #map-canvas {
    .store-locator__tooltip {
      padding-left: 10px;
      height: 163px;
      width: 191px;
    }
  }
  /* .store-locator__tooltip {
    &-book-appointment {
      @include breakpoint($small-down) {
        border: 2px solid $color-black;
        color: $color-black;
        display: block;
        font-family: $brandon-text-bold;
        letter-spacing: 0.3em;
        padding: 8px 0 8px 10px;
        margin: 10px 0 0;
        text-transform: uppercase;
        .icon {
          background: transparent url("/media/export/cms/appointment_booking/common/calendar_store_mob.jpg") no-repeat;
          width: 31px;
          height: 37px;
          cursor: pointer;
          margin-right: 3px;
        }
      }
    }
    &-name {
      font-family: $brandon-text-bold;
      font-size: 14px;
    }
  } */

  .store-locator-section {
    .gm-style-iw {
      top: 15px;
    }
  }
}

.store-locator--desktop {
  .store-locator-legend {
    padding: 20px 0px 0px 0px;
    font-size: 12px;
    font-family: $brandon-text-bold;
    text-transform: uppercase;
  }
  .store-locator-legend ul {
    margin: 0;
    list-style-type: none;
  }
  .store-locator-legend ul li {
    padding-right: 20px;
    display: inline;
  }
  .store-locator-legend ul li img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
  }
  .store-locator-legend ul li span {
    padding-left: 10px;
  }
  .store-locator__main {
    margin-bottom: 10px;
    .column {
      .section-book {
        background-color: #f0f0f0;
        height: 228px;
        margin-top: 29px;
        &__inner {
          padding: 0;
          position: relative;
        }
        &__text-wrapper {
          left: 0;
          padding: 20px 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          z-index: 1;
        }
        &__text {
          margin: 0 auto;
        }
        &__img {
          height: 228px;
          width: 616px;
        }
        &__title {
          font-family: 'Brandon Text Bold';
          font-size: 24px;
          letter-spacing: 0.24em;
          line-height: 34.16px;
          margin-bottom: 0;
          margin-left: 28px;
          text-align: left;
          text-transform: uppercase;
        }
        &__subtitle {
          margin-right: auto;
          margin-top: 0;
          margin-left: 28px;
          text-align: left;
          width: 290px;
          p {
            font-size: 20px;
            line-height: 1.35em;
            padding: 0;
            margin: 0;
            text-align: center;
          }
          .btn {
            margin-top: 20px;
            text-align: center;
          }
        }
        &__button {
          background: #000000 none repeat scroll 0% 0%;
          border: 0px none;
          color: #fff;
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          height: 38px;
          letter-spacing: 2.5px;
          line-height: 38px;
          margin: 0 auto;
          overflow: visible;
          padding: 0px 11px;
          text-transform: uppercase;
          width: auto;
        }
        &__button:hover {
          background-color: #ff4661;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  .store-locator__stores {
    height: 669px;
    overflow-y: scroll;
  }
  .fss-no-stores {
    padding-top: 3%;
  }
  ul.resp-tabs-list {
    .resp-tab-item {
      text-align: center;
      width: 33%;
      height: 55px;
      font-family: $brandon-text-bold;
      font-size: 11px;
      line-height: 15px;
      padding-top: 4%;
    }
  }
  .store-locator_checkbox {
    position: static;
  }
  .store-locator_international {
    margin: 0px 0px 0px 180px;
  }
}
