/// Mixins, Placeholders and Variables.
@mixin checkout-sidebar-panel-border {
  margin-top: 6px;
  border: 5px solid $color-gray-light-alt;
  padding: 15px 15px 20px 15px;
  background: $color-white;
  &__title {
    margin-top: 34px;
  }
}

@mixin checkout-mobile-header {
  background-color: $color-gray-light-alt;
  padding: 10px 15px;
  border: none !important;
  text-indent: 0;
  font-size: 16px;
  text-align: left;
  line-height: 22px;
}

@mixin checkout-label-and-checkbox-form-item {
  input[type='checkbox'] ~ label,
  input[type='checkbox'] ~ .label {
    display: block;
    padding-left: 20px;
    position: relative;
  }
  input[type='checkbox'] ~ label:before,
  input[type='checkbox'] ~ .label:before {
    height: 15px;
    left: 0;
    margin-right: 5px;
    position: absolute;
    top: 3px;
    width: 15px;
  }
}

// Fixing the radio buttons being vertically misaligned
label:before {
  margin-top: -3px;
}

.checkout__sidebar {
  .order-summary-panel {
    .clearpay {
      display: none;
    }
  }
}

.delivery-address-part {
  width: 90%;
}

.review-panel {
  @include payment-panel;
  &__title {
    @include checkout-section-header;
  }
  .checkbox {
    display: block; // bb_base/scss makes the .checkbox div, which contains for the uk the terms stuff needed for
    // guest/anon. checkout, invisible, so we're "redisplaying" it here
  }
  .collect-point-address {
    padding: 0 10px;
    @include breakpoint($landscape-up) {
      padding: 0 20px;
    }
    .selectBox-dropdown {
      border: 1px solid #{$color-gray};
      margin-top: 10px;
      width: 90%;
    }
  }
  .view-address {
    margin-bottom: 10px;
    .select-address-part {
      padding: 0 10px;
      @include breakpoint($landscape-up) {
        padding: 0 20px;
      }
      .payment-address {
        width: 100%;
      }
    }
    .address-header {
      background: #{$color-gray-light-alt};
      border-bottom: 1px solid #{$color-gray};
      border-top: 1px solid #{$color-gray};
      font-size: 24px;
      margin: 30px 0;
      padding: 14px 0;
    }
    .change-address {
      float: #{$rdirection};
      margin: 0 12px;
      padding: 7px 12px;
    }
  }
  .chckt-sdk {
    .chckt-pm {
      border: none;
      &__details {
        background: #{$color-white} !important; // third party override style
        padding-#{$ldirection}: 0;
        .chckt-input-field--recurring {
          background: #{$color-white};
          border: 1px solid #{$color-black};
          border-radius: 0;
          height: 40px;
          padding: 12px 10px;
          @include breakpoint($landscape-up) {
            height: 45px;
            padding: 8px 10px;
          }
        }
      }
      &__header {
        background: #{$color-white};
        padding-#{$ldirection}: 0;
        .chckt-pm__radio-button {
          display: none;
        }
      }
      &__image {
        #{$rdirection}: 2px;
        margin: 0;
      }
      &__name {
        color: #{$color-black};
      }
    }
    .chckt-form--max-width {
      color: #{$color-black};
      max-width: 312px;
      min-width: 274px;
      @include breakpoint($landscape-up) {
        max-width: 472px;
        min-width: 345px;
      }
      .chckt-form-label {
        &__text {
          color: #{$color-black};
          &--dark {
            padding: 0 30px;
            text-transform: initial;
          }
        }
        &--exp-date {
          min-width: 122px;
          width: 45%;
          @include breakpoint($landscape-up) {
            min-width: 182px;
            width: 46%;
          }
        }
        &--cvc {
          float: #{$ldirection};
          max-width: 154px;
          min-width: 108px;
          padding-#{$ldirection}: 2%;
          @include breakpoint($landscape-up) {
            max-width: 291px;
            padding-#{$ldirection}: 22px;
            width: 54%;
          }
        }
        &__error-text {
          color: #{$color-red};
          text-transform: none;
        }
      }
    }
    .chckt-input-field {
      border-radius: 0;
      border-color: #{$color-black};
      color: #{$color-black};
      height: 40px;
      @include breakpoint($landscape-up) {
        height: 45px;
      }
      &--cvc {
        max-width: 100%;
      }
      &--error {
        color: #{$color-red};
        border: 2px solid #{$color-red};
      }
    }
    .chckt-button-container {
      margin-bottom: 10px;
      width: 96%;
      @include breakpoint($landscape-up) {
        margin-bottom: 17px;
        width: 88%;
      }
      .chckt-more-pm-button {
        border: none;
        padding-#{$ldirection}: 0;
        &__icon {
          display: none;
        }
        &__text {
          color: #{$color-black};
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1px;
          margin-#{$ldirection}: 0;
          text-decoration: underline;
        }
      }
      .chckt-button {
        background: #{$color-black};
        height: 40px;
        line-height: 1.4;
        margin-top: 0;
        padding: 10px;
        &--disabled {
          opacity: 0.7;
          padding: 0 10px;
          height: 40px;
        }
        &:hover {
          background: #{$color-black} !important; // overwrite base file transparant style  _button.scss
        }
      }
    }
  }
  .adyen-payment-form {
    width: 100%;
    .payment-container {
      border-top: 1px solid #{$color-black};
      width: 100%;
      .payment-option {
        border-bottom: 1px solid #{$color-black};
        min-height: 60px;
        width: 100%;
        padding: 0 10px;
        .payment_options-terms,
        .adyen-payment {
          display: none;
          margin-bottom: 20px;
          @include breakpoint($landscape-up) {
            margin-#{$ldirection}: 20px;
          }
        }
        input[type='radio'] {
          &:checked {
            & ~ label {
              .adyen-payment,
              .payment_options-terms {
                display: block;
              }
            }
          }
        }
        .paypal-terms {
          .local-submits {
            display: block;
            .paypal-express-btn {
              background: #{$color-black};
              color: #{$color-white};
              padding: 5px 40px;
            }
          }
        }
        .related-media {
          display: inline-block;
          padding: 15px;
          vertical-align: middle;
          @include breakpoint($landscape-up) {
            vertical-align: baseline;
          }
          &.cc {
            padding: 5px;
            margin-bottom: 10px;
            .card-img {
              margin: 0 2px;
              vertical-align: middle;
              width: 38px;
              @include breakpoint($landscape-up) {
                margin-#{$ldirection}: 6px;
                width: 40px;
              }
            }
          }
          &.pp {
            padding: 10px;
            vertical-align: sub;
            @include breakpoint($landscape-up) {
              vertical-align: baseline;
            }
            .pp-image {
              height: 21px;
              width: 81px;
            }
          }
          &.afterpay-label {
            padding: 0;
            @include breakpoint($landscape-up) {
              padding: 10px 0;
            }
            .afterpay-image {
              height: 24px;
              margin: 0 7px;
              width: 90px;
              vertical-align: super;
              @include breakpoint($landscape-up) {
                vertical-align: middle;
              }
            }
          }
        }
        .after_pay-installment {
          .afterpay-learnmore {
            span {
              background: #{$color-black};
              color: #{$color-white};
            }
          }
        }
        .afterpay-button {
          float: #{$ldirection};
          height: 40px;
          margin: 10px;
          width: 310px;
          .afterpay-img {
            vertical-align: middle;
          }
        }
        .payment-terms {
          clear: both;
          .overlay-wrapper {
            display: inline;
          }
        }
        #adyen-payment-form {
          input[type='checkbox'] {
            & ~ span {
              &::before {
                background: #{$color-white};
                border: 2px solid #{$color-black};
                border-radius: 0;
                content: ' ';
                cursor: pointer;
                display: inline-block;
                height: 16px;
                position: absolute;
                top: 1px;
                width: 16px;
                #{$ldirection}: 0;
              }
            }
            &:checked {
              & ~ span {
                &::after {
                  @include swap_direction(border-width, 0 2px 2px 0);
                  #{$ldirection}: 6px;
                  background: #{$color-white};
                  border-color: #{$color-black};
                  border-style: solid;
                  border-radius: 0;
                  content: ' ';
                  cursor: pointer;
                  display: block;
                  height: 8px;
                  position: absolute;
                  transform: rotate(45deg);
                  top: 4px;
                  width: 4px;
                  -webkit-transform: rotate(45deg);
                }
              }
            }
          }
        }
        .paypal-button {
          margin-top: 10px;
        }
      }
    }
    .local-submits {
      display: none;
    }
    .signature-part {
      display: inline-block;
      margin: 20px 0;
    }
  }
  .review-edit-content {
    .content {
      padding: 0;
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 0 20px);
      }
    }
    .adyen-content {
      margin-top: 20px;
      .gift-card-entry {
        &-container {
          margin: 10px 0;
          .gift-card {
            &-number {
              float: #{$ldirection};
              width: 60%;
              @include breakpoint($landscape-up) {
                width: 40%;
              }
            }
            &-pin {
              float: #{$ldirection};
              margin-#{$ldirection}: 5%;
              width: 35%;
              @include breakpoint($landscape-up) {
                width: 25%;
              }
            }
          }
          input[type='text'] {
            height: 40px;
          }
          .continue-btn {
            .form-submit {
              height: 40px;
              margin-top: 10px;
              width: 100%;
              @include breakpoint($landscape-up) {
                margin-top: 0;
                width: 70%;
              }
            }
          }
        }
      }
    }
    .form-container {
      .payment-form {
        width: 100%;
        margin-bottom: 10px;
        @include breakpoint($landscape-up) {
          width: 45%;
        }
        .form-item {
          .label-content {
            margin-bottom: 5px;
            float: #{$ldirection};
            font-weight: bold;
            @include breakpoint($landscape-up) {
              margin-bottom: 10px;
            }
          }
          &.checkbox {
            .label-content {
              float: none;
            }
          }
        }
        .selectBox {
          &-arrow {
            top: 8px;
            width: 16px;
            font-size: 7px;
            padding-#{$rdirection}: 0;
            font-weight: bold;
            color: #{$color-black};
            &:before {
              font-weight: bold;
            }
          }
          &-label {
            color: #{$color-black};
          }
        }
        .selectbox {
          min-width: auto;
          background-size: 12px;
          background-position: 97% 47%;
          font-weight: bold;
        }
        &__item {
          padding: 0;
        }
      }
      .card-image {
        margin-bottom: 15px;
        float: #{$ldirection};
        width: 100%;
      }
      .form-item-card {
        .card-type {
          width: 100%;
          @include breakpoint($landscape-up) {
            width: 65%;
          }
        }
      }
      .creditcard-option {
        width: 100%;
        border-top: 1px solid #{$color-black};
      }
      .payment-options {
        @include swap_direction(padding, 20px 0);
        @include breakpoint($landscape-up) {
          @include swap_direction(padding, 25px 0);
        }
      }
      .paypal-option {
        width: 100%;
        border-top: 1px solid #{$color-black};
        border-bottom: 1px solid #{$color-black};
      }
      .paypal-image {
        @include swap_direction(margin, 0 3px);
        width: 70px;
        vertical-align: middle;
      }
      .card-number {
        float: #{$ldirection};
        width: 100%;
      }
      .card-cvn {
        float: none;
        width: 100%;
        @include breakpoint($landscape-up) {
          display: inline-block;
          margin-#{$ldirection}: 0;
        }
        .overlay-wrapper {
          display: inline-block;
        }
        .label-content {
          width: 100%;
        }
        input[type='text'] {
          width: 100%;
          @include breakpoint($landscape-up) {
            display: inline-block;
            margin-#{$ldirection}: 0;
            width: 32%;
          }
        }
        .security-code {
          display: inline-block;
          margin-top: 5px;
          @include breakpoint($landscape-up) {
            @include swap_direction(margin, 0 0 0 10px);
          }
        }
      }
      .afterpay-option {
        width: 100%;
        border-bottom: 1px solid #{$color-black};
        margin-bottom: 20px;
        input[type='radio'] {
          & ~ label {
            &:before {
              vertical-align: top;
              margin-top: 9px;
              @include breakpoint($landscape-up) {
                margin-top: 7px;
              }
            }
          }
        }
        .afterpay {
          &-text {
            display: inline-block;
          }
          &-image {
            height: auto;
            vertical-align: bottom;
          }
          &-subtext {
            display: block;
            margin-top: 5px;
          }
        }
      }
      .afterpay-learnmore {
        span {
          @include swap_direction(padding, 2px 3px 0 1px);
          width: 16px;
          height: 16px;
          display: inline-block;
          border-radius: 50%;
          border: 1px solid #{$color-black};
          background: #{$color-white};
          color: #{$color-black};
          font-size: 10px;
          font-weight: bold;
          line-height: 1.2;
          text-transform: lowercase;
          font-style: italic;
          text-align: center;
        }
      }
      .afterpay-disclaimer {
        display: block;
      }
      .card-expiry-date {
        @include swap_direction(margin, 10px 0 25px);
        @include breakpoint($landscape-up) {
          margin-top: 0;
          width: 100%;
          display: block;
        }
        .card-expiration {
          &-month,
          &-year {
            width: 49%;
            float: #{$ldirection};
            display: inline-block;
            @include breakpoint($landscape-up) {
              width: 42%;
            }
          }
          &-month {
            .label-content {
              width: 100%;
            }
          }
          &-year {
            padding-top: 9.7%;
            float: #{$rdirection};
            @include breakpoint($landscape-up) {
              padding-top: 11.2%;
            }
          }
        }
        .section-separation {
          display: none;
        }
      }
    }
    .payment_method-container {
      #checkout_payment {
        .form-item.card-cvn {
          #security-code {
            display: block;
          }
        }
      }
    }
    #checkout_saved_payment {
      .form-item {
        #security-code {
          display: block;
        }
      }
    }
  }
}

.paypal-loading {
  background-size: 23px;
  background-position: top;
  display: inline-block;
  height: 30px;
  margin-#{$rdirection}: 10px;
  width: 30px;
}
.paypal-process {
  opacity: 0.2;
}
.paypal-overlay {
  background: #{$color-black};
  #{$ldirection}: 0;
  height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.paypal-processing {
  #{$ldirection}: 0;
  margin: 0 auto;
  padding: 10px;
  position: fixed;
  #{$rdirection}: 0;
  text-align: center;
  top: 25%;
  width: 100%;
  z-index: 999;
  .process-content {
    background: #{$color-white};
    max-width: 335px;
    margin: 0 auto;
    position: relative;
    padding: 30px 10px;
    z-index: 999;
  }
  .paypal-logo {
    padding-top: 20px;
    width: 90px;
  }
  .bb-logo {
    padding-bottom: 25px;
  }
}

#checkout-header {
  .shopping-region {
    float: right;
  }
  .site-logo__image {
    text-indent: 0;
  }
}

.viewcart-panel {
  .cart-items {
    .cart-item__remove-form {
      .remove_link {
        @include breakpoint($small-down) {
          width: auto;
        }
      }
    }
  }
}

.viewcart {
  &-panel {
    .checkout__sidebar & {
      padding: 13px 19px;
    }
    &__title {
      .checkout__content & {
        border-bottom: 2px solid $color-black;
      }
      .checkout__sidebar & {
        @include breakpoint($small-down) {
        }
        @include breakpoint($medium-up) {
          display: inline-block !important;
          padding-bottom: 0;
        }
        text-indent: 0;
        border-bottom: none;
        margin-bottom: 0;
        margin-top: 0;
        & #shopping-bag-title-edit {
          border-bottom: none;
          @include breakpoint($small-down) {
            font-size: 16px;
          }
        }
      }
    }
    #shopping-bag-edit {
      .checkout .checkout__sidebar & {
        @include breakpoint($medium-up) {
          bottom: 10px;
        }
        @include breakpoint($small-down) {
          display: block;
          right: 7px;
        }
      }
    }
  }
  &-header {
    .checkout__sidebar & {
      padding-bottom: 0;
      min-height: 35px;
      padding-top: 3px;
      @include breakpoint($medium-up) {
        border-bottom: 1px solid $color-black;
      }
      @include breakpoint($small-down) {
        border-bottom: none;
        .edit {
          display: none;
        }
      }
    }
  }
  // cart items header
  &-buttons {
    &-panel {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $color-gray;
      .content {
        padding-right: 20px;
      }
    }
  }
  @include breakpoint($small-down) {
    .shipmethod-panel {
      .ship-method-group-label {
        .overlay-wrapper {
          display: inline-block;
          float: right;
        }
        a.more-info {
          background: url(../../../../../media/export/images/click_collect/more-info-icon.png)
            no-repeat right center;
          padding-right: 15px;
          color: #{$color-bundle-gray};
          &.loading {
            background: url(../../bb_base/img/ajax-loading.gif) no-repeat right center;
            padding-right: 25px;
            background-size: contain;
          }
        }
      }
    }
  }
  @include breakpoint($medium-up) {
    .shipmethod-panel {
      .content {
        padding-right: 20px;
      }
      &__title {
        padding-right: 20px;
        text-align: right;
        border-bottom: none;
      }
      .ship-method-group-label {
        width: 48%;
        text-align: right;
        display: inline-block;
        padding-bottom: 20px;
        @include h24;
        &.more_info_label {
          width: 64%;
          .overlay-wrapper {
            display: inline-block;
            margin-left: 5px;
            float: right;
          }
          a.more-info {
            margin-left: 20px;
            text-decoration: none;
            font-size: 12px;
            background: url(../../../../../media/export/images/click_collect/more-info-icon.png)
              no-repeat right center;
            padding-right: 15px;
            color: #{$color-bundle-gray};
            span {
              border-bottom: 2px solid;
              padding-bottom: 5px;
            }
            &.loading {
              background: url(../../bb_base/img/ajax-loading.gif) no-repeat right center;
              padding-right: 25px;
              background-size: contain;
            }
          }
        }
        &.minimise {
          width: 35%;
        }
      }
      .home-form-item {
        width: 60%;
        margin-left: 40%;
        margin-left: 40%;
      }
      .form-item {
        text-align: right;
        select,
        a {
          position: relative;
          left: 44%;
          width: 56%;
          padding-right: 30px;
        }
      }
      .date22-form-item {
        margin-left: 40%;
        width: 60%;
        select {
          width: 74%;
        }
      }
      .delivery-note {
        text-align: right;
        margin-top: 12px;
      }
    }
    .donation-panel {
      .content {
        padding-right: 20px;
      }
      &__title {
        padding-top: 17px;
        padding-right: 20px;
        text-align: right;
        border-bottom: none;
        margin-bottom: 0px;
      }
    }
  }
  .checkout__sidebar {
    .tab-switcher {
      .tab-content {
        #past-purchases-pane {
          .past-purchases-panel {
            .content {
              .product-list {
                min-height: inherit;
              }
              a {
                color: #{$color-bundle-gray};
              }
            }
          }
        }
        #favorites-pane {
          .favorites-panel {
            .content {
              .favs {
                .product-list {
                  min-height: inherit;
                }
              }
              a {
                color: #{$color-bundle-gray};
              }
            }
          }
        }
      }
    }
  }
  #recommended-products-panel {
    margin-top: 45px;
    .content {
      .recommended-product-items {
        .recommended-item {
          .quickshop {
            display: block;
          }
          .description {
            .info {
              .produce_subname {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.checkout {
  &.pg_wrapper {
    // get different margin settings, for small browser sizes, for checkout's content area
    @include breakpoint($small-down) {
      padding-top: 0;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .wp_samples-page {
    .offerspick {
      &__button--continue_promo {
        display: none;
      }
      &-action-buttons {
        text-align: #{$rdirection};
      }
    }
  }
  input[type='text'],
  input[type='password'] {
    @include breakpoint($small-down) {
      margin-bottom: 0;
    }
  }
  label {
    @include breakpoint($small-down) {
      font-size: 12px;
    }
  }
  .review-panel {
    input[type='submit'] {
      &.afterpay-button {
        float: #{$rdirection};
        text-align: #{$ldirection};
        background: #{$color-black} url(/media/images/checkout/logo-clearpay-white.png) no-repeat 70%
          48%;
        background-size: 100px 18px;
        padding: 3.5% 20%;
        @include breakpoint($small-screen) {
          padding: 3.5% 18%;
          background-position: 75% 48%;
        }
        @include breakpoint($landscape-up) {
          width: 260px;
          background-position: 85% 48%;
          padding: 0 4%;
          &:hover {
            background: #{$color-cta-hover} url(/media/images/checkout/logo-clearpay-white.png) no-repeat
              85% 48%;
            background-size: 100px 18px;
          }
        }
      }
      &.card-payment {
        @include breakpoint($landscape-up) {
          float: #{$rdirection};
        }
      }
    }
  }
  .loading {
    position: static;
    @include opacity(1);
    * {
      @include opacity(0.8);
    }
  }
  /* Custom Overlay CMS */
  .overlay-link-cms {
    &.loading {
      padding-right: 25px;
      background-position: right center;
      background-size: contain;
    }
  }
  /* Custom Overlay CMS */

  &__header {
    &-item-count {
      &:before {
        content: '';
      }
      .checkout__content & {
        @include breakpoint($small-down) {
          display: none;
        }
        display: block;
        position: absolute;
        bottom: 0;
        right: 20px;
        text-align: right;
        padding-bottom: 15px;
        min-width: 100px;
        @include h8;
        font-size: 14px;
      }
      .checkout__sidebar & {
        text-align: left;
        display: inline-block;
        margin-left: 30px;
        @include breakpoint($small-down) {
          display: none;
        }
      }
    }
  }
  &__content {
    @include breakpoint($medium-up) {
      .viewcart & {
        width: 100% - $checkout-sidebar-width; // override the viewcart width, coming from bb_base, narrowing it for the UK, to accomodate the wider sidebar below (per the mocks given)
      }
    }
  }
  &__panel-title,
  &__content &__panel-title {
    @include breakpoint($small-down) {
      @include checkout-mobile-header;
      padding-left: 10px;
      padding-right: 10px;
      line-height: 18px;
    }
  }
  &__subtitle {
    @include breakpoint($small-down) {
      font-size: 12px;
      margin-bottom: 5px;
      padding-bottom: 5px;
    }
  }
  &__sidebar {
    @include breakpoint($medium-up) {
      .viewcart & {
        width: $checkout-sidebar-width; // override the viewcart width, coming from bb_base, widening it for the UK (per the mocks given)
      }
    }

    // tabbing system (pulled over from checkout.css, on BrZ UK1)
    .tab-switcher {
      @include checkout-sidebar-panel-border;
      padding: 0;
      @include breakpoint($small-down) {
        display: none;
      }
    }
    .tab-bar {
      @include clearfix;
      @include checkout-sidebar-panel;
      padding: 0;
      li {
        @include clearfix;
        display: block;
        float: left;
        background-color: $color-gray-light-alt;
      }
    }
    .tab {
      display: block;
      width: 100%;
      text-decoration: none;
      color: $color-black;
      @include breakpoint($medium-up) {
        padding: 10px 5px;
        @include h22;
      }
      @include breakpoint($large-up) {
        @include h12;
        padding: 10px;
      }
      &.active {
        background-color: white;
      }
    }
    .tab-content {
      padding: 15px;
    }
    .tab-pane {
      opacity: 0;
      height: 0;
      overflow: hidden;
      -webkit-transition: opacity 1s ease;
      -moz-transition: opacity 1s ease;
      -o-transition: opacity 1s ease;
      -ms-transition: opacity 1s ease;
      transition: opacity 1s ease;
      &.active {
        display: block;
        opacity: 1;
        height: auto;
        footer {
          display: block;
          text-align: right;
        }
      }
      header,
      footer {
        display: none;
      }
    }
    // end tabbing system

    // stuff for the checkout sidebar samples panel, the product rows
    .product {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $color-gray;
      &:first-child {
        margin-top: 0;
        border-top: none;
      }
      @include breakpoint($small-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .checkout__panel-title {
      @include breakpoint($small-down) {
        text-align: left;
      }
    }
    .panel {
      @include breakpoint($small-down) {
        background: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .product-img {
      max-width: 80px; // to match the small image size, which we're using for product images (regular and samples)
      margin: 0 10px 0 0;
      float: left;
    }
    .details {
      margin-top: 0;
      a {
        color: #{$color-bundle-gray};
      }
      .add-to-cart {
        .btn {
          background-color: #{$color-black};
        }
      }
    }

    // hide the samples panel's add and no thanks buttons when in the sidebar (not the popup)
    .add-button,
    .no-thanks {
      display: none;
    }
    .remaining,
    .remaining p {
      margin-bottom: 0;
    }
    .shipmethod-panel {
      padding: 10px 20px !important;
      #checkout_shipmethod {
        .ship-method-group-label.more_info_label {
          label {
            .overlay-wrapper {
              display: inline-block;
              margin-left: 5px;
              float: right;
            }
            a.more-info {
              float: right;
              text-decoration: none;
              margin-top: -10px;
              background: url(../../../../../media/export/images/click_collect/more-info-icon.png)
                no-repeat right center;
              padding-right: 15px;
              span {
                border-bottom: 2px solid;
                padding-bottom: 5px;
                font-size: 11px;
              }
              &.loading {
                background: url(../../bb_base/img/ajax-loading.gif) no-repeat right center;
                padding-right: 25px;
                background-size: contain;
              }
            }
          }
        }
        .ship-method-home-group,
        .ship-method-collection-group {
          margin-top: 20px;
        }
      }
    }
    .links-panel {
      .content {
        ul.links_list {
          li a {
            border-width: 0px 0px 2px 0px;
          }
        }
      }
    }
    .offer-code-panel {
      padding: 10px 20px;
      margin-top: 6px;
      .content {
        p {
          margin: 0px 0px 15px 0px;
        }
        #offer_code {
          margin-bottom: 15px;
        }
      }
    }
  }
  &__new-account,
  &__return-user {
  }
  a.btn.edit {
    top: 10px;
    padding: 0.5em 1em;
    @include breakpoint($small-down) {
      padding: 0.35em 0.75em;
      font-size: 12px;
      right: 7px;
    }
  }
  .gift-options-display {
    p {
      word-wrap: break-word;
    }
  }
  .checkout__sidebar {
    #need-help-panel {
      display: none;
    }
  }
}

.order-summary {
  &-panel {
    .checkout__sidebar & {
      @include breakpoint($small-down) {
        display: block !important;
        visibility: visible;
      }
      header {
        @include breakpoint($small-down) {
          display: block;
        }
      }
      .content {
        @include breakpoint($small-down) {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
      .label {
        @include breakpoint($small-down) {
          width: 75%;
        }
        font-family: $brandon-text;
      }
      .value {
        @include breakpoint($small-down) {
          width: 25%;
        }
      }
    }
    .checkout__content & {
      header {
        //@include breakpoint($small-down) {
        display: block;
        //}
        h2 {
          padding-right: 20px;
        }
      }
      @include breakpoint($small-down) {
        border-top: none;
        padding-top: 0px;
      }
    }
    .total {
      font-family: $brandon-text-bold;
    }
    .clearpay {
      @include swap_direction(padding, 10px 20px 30px);
      text-transform: none;
      width: 100%;
      text-align: #{$rdirection};
      font-family: #{$brandon-text};
      line-height: 1.7;
      border-bottom: 1px solid #{$color-gray-border};
      .afterpay-learnmore {
        span {
          @include swap_direction(padding, 2px 3px 0 1px);
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid #{$color-black};
          font-style: italic;
          font-weight: bold;
          text-align: center;
          font-size: 12px;
          background: #{$color-white};
          color: #{$color-black};
          line-height: 1;
          text-decoration: none;
        }
      }
      .single-message {
        .close_link {
          display: none;
        }
      }
    }
    .value {
      padding-right: 20px;
      line-height: 19px;
    }
  }
  &__total-label,
  &__total-value {
    border-top: none;
    @include breakpoint($large-up) {
      border-bottom: 0;
    }
  }
}

.need-help-panel {
  .checkout__sidebar & {
    @include breakpoint($medium-up) {
      @include checkout-sidebar-panel;
      @include checkout-sidebar-panel-border;
    }
  }
  .additional-info {
    margin: 0;
    padding: 0;
    //line-height: normal;
  }
  &__title {
    @include breakpoint($large-up) {
      // specificity
      .checkout__sidebar & {
        @include breakpoint(text--short);
        //border-bottom: none;
      }
    }
  }
}

.need-help-panel {
  @include breakpoint($medium-up) {
    h4 {
      margin-bottom: 8px;
      letter-spacing: 0.24em;
      * {
        font-family: $brandon-text-bold;
        text-transform: uppercase;
        color: #{$color-bundle-gray};
        font-size: 14px;
        line-height: 19px;
        vertical-align: top;
        padding-bottom: 5px;
      }
      a {
        @include link($color-pink, $color-black);
      }
    }
  }
  @include breakpoint($small-down) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    .content {
      h4 {
        a {
          border-width: 0px 0px 2px 0px;
          padding-top: 0px;
          color: #{$color-bundle-gray};
          border-bottom: 2px solid #{$color-bundle-gray};
        }
      }
    }
  }
  @include breakpoint($medium-down) {
    .phone {
      h4 {
        span.text {
          display: block;
        }
      }
    }
  }
}

.links-panel {
  .checkout__sidebar & {
    @include breakpoint($medium-up) {
      @include checkout-sidebar-panel;
      @include checkout-sidebar-panel-border;
    }
  }
  .content {
    @media screen and (min-width: 768px) {
      .links_list {
        li {
          border: none;
          .overlay-link {
            border-width: 0px 0px 2px 0px !important;
          }
          a,
          a:hover {
            color: #{$color-bundle-gray};
            border-bottom: 2px solid #{$color-bundle-gray};
          }
        }
      }
    }
    @include breakpoint($small-down) {
      margin-left: 15px;
      margin-right: 20px;
    }
    .link {
      @include breakpoint($medium-up) {
        border-bottom: none;
        margin-bottom: 5px;
        a {
          @include link($color-pink, $color-black);
        }
      }
      @include breakpoint($small-down) {
        border: none;
        text-transform: none;
        font-family: $brandon-text;
        letter-spacing: 1px;
        display: block;
        padding: 10px 0 0;
      }
      .overlay-wrapper {
        color: #000000;
      }
    }
    a {
      color: #{$color-bundle-gray};
    }
  }
  &__title {
    .checkout__sidebar & {
      margin-top: 0;
    }
  }
}

.shipmethod {
  &-panel {
    padding-top: 14px;
    .checkout__sidebar & {
      @include breakpoint($medium-up) {
        @include checkout-sidebar-panel;
      }
    }
    @include breakpoint($small-down) {
      .content {
        padding: 0 10px 20px;
      }
    }
    .ship-method-group {
      &-label {
        @include breakpoint($medium-up) {
          text-transform: uppercase;
        }
        padding-bottom: 5px;
        @include breakpoint($small-down) {
          float: left;
          padding-right: 20px;
        }
      }
      & .selectbox {
        margin: 5px 0;
      }
      &,
      & label,
      &-label label,
      & .selectbox {
        @include breakpoint($small-down) {
          font-size: 10px;
        }
      }
    }
    .date22-form-item {
      @include breakpoint($medium-up) {
        width: 48%;
      }
    }
    .ship-method-collection-group {
      @include breakpoint($small-down) {
        clear: both;
      }
    }
  }
}

.offer-code {
  &-panel {
    .content {
      @include breakpoint($small-down) {
        padding-left: 10px;
        padding-right: 10px;
        p {
          margin-bottom: 15px;
        }
        form {
          position: relative;
        }
        input[type='text'] {
          width: 55%;
        }
        input.btn[type='submit'] {
          width: 40%;
          position: absolute;
          right: 0;
        }
        .note {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.recommended {
  &-products {
    &-panel {
      border-top: 1px solid $color-gray;
      margin-top: 20px;
      padding-top: 20px;
      &__title {
        padding-bottom: 10px;
      }
      .content {
        padding: 0 20px;
      }
    }
  }
  &-product {
    &-items {
      border-top: 1px solid $color-black;
      padding-top: 20px;
    }
  }
  &-item {
    border-right: 1px solid $color-gray;
    padding: 0 20px;
    &:last-child {
      border-right: none;
    }
  }
}

.gift-message {
  &-h {
    &__title {
      display: block;
      margin-top: 25px;
    }
  }
}

.registration {
  &-panel {
    &__title {
      @include checkout-section-header;
    }
  }
}

.review {
  &-edit {
    &-content {
      position: relative;
    }
  }
  &-panel {
    .field-are-required {
      top: 0;
      right: 0;
      font-size: 11px;
    }
    .gift-cards {
      margin-bottom: 20px;
    }
    .payment-type {
      margin-top: 20px;
    }
    .payment-option {
      .related-media {
        display: block;
        @include breakpoint($small-down) {
          img {
            width: 25px;
          }
        }
        @include breakpoint($medium-up) {
          margin-top: 12px;
        }
      }
    }
  }
}

// pieces borrowed from ~/perlgem/sites/brz/uk1/css/pc/conditional/checkout.css,
// pieces that are "functional" in nature, in that certain pieces of functionality
// don't work without them.
#checkout-header {
  #mobile-cart-button {
    display: none;
    &.inactive:before {
      content: '+ ';
    }
    &.active:before {
      content: '- ';
    }
    @media (max-width: 768px) {
      display: inline-block;
    }
  }
  ul.nav-links {
    float: right;
    list-style-type: none;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 1em;
    }
    .checkout-text {
      display: none;
    }
  }
  .site-logo {
    display: block; // override the "responsive" style, that hides the logo when sizing below a certain size
  }
}

#mobile-breadcrumb {
  display: none;
  list-style-type: none;
  margin: 0.5em 0;
  li {
    display: inline-block;
  }
  @media (max-width: 768px) {
    body.active-panel-shipping &,
    body.active-panel-registration &,
    body.active-panel-review & {
      display: block;
    }
  }
}

.guarantee-panel {
  @include breakpoint($small-down) {
    padding: 0;
    background-color: $color-nude !important;
    margin-top: 20px;
    margin-bottom: 20px;
    &__title {
      padding: 15px 0;
      &.checkout__panel-title {
        border-bottom: 1px solid #000 !important;
        margin: 0 10px 10px 15px;
      }
    }
    .content {
      margin-left: 15px;
      margin-right: 10px;
    }
  }
  a {
    color: #{$color-bundle-gray};
  }
  .checkout__content & {
    @include breakpoint($small-down) {
      display: none;
    }
  }
  .checkout__sidebar & {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}

@import 'checkout-cart-item';
@import 'checkout-panel-account';
@import 'checkout-panel-shipping';
@import 'checkout-panel-review';
@import 'checkout-samples';
@import 'checkout-confirmation';
@import 'checkout-offers-promos';
@import 'checkout-viewcart-summary';
@import 'checkout-viewcart-samples';

.touch {
  select {
    min-width: 90%;
  }
}

.address-book-page #myaccount-wrapper {
  #content .address-book {
    a.add-address-link {
      margin: 15px 15px;
    }
  }
}
/* Donations */
div.column section.donations.panel {
  div.donation_info_panel {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
    @include breakpoint($small-down) {
      margin-left: 0px;
    }
  }
  div.donation_logo {
    float: left;
    height: 100%;
    padding-right: 20px;
    @include breakpoint($small-down) {
      display: none;
    }
  }
  div.donation_logo_mobile {
    float: left;
    height: 100%;
    padding-right: 20px;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  div.donation_message {
    margin-left: 140px;
    @include breakpoint($small-down) {
      margin-left: 130px;
      display: none;
    }
  }
  div.donation_message_mobile {
    margin-left: 125px;
    font-size: 12px;
    @include breakpoint($medium-up) {
      margin-left: 130px;
      display: none;
    }
  }
  div.form_element.onoffswitch {
    position: relative;
    width: 222px;
    letter-spacing: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .onoffswitch-checkbox {
    display: none !important;
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px;
  }
  label.onoffswitch-label:before {
    display: none !important;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
  }
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 13px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .onoffswitch-inner:after {
    content: 'Slide right to donate \00A3 1';
    padding-right: 10px;
    color: #838279;
    text-shadow: 1px 1px #ffffff;
    background: #c0c0c1; /* Old browsers */
    background: -moz-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #c0c0c1),
      color-stop(26%, #ffffff),
      color-stop(100%, #e9e9ea)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      top,
      #c0c0c1 0%,
      #e9e9ea 26%,
      #ffffff 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* IE10+ */
    background: linear-gradient(to bottom, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0c0c1', endColorstr='#e9e9ea',GradientType=0 ); /* IE6-9 */
    text-align: right;
  }
  .onoffswitch-inner:before {
    content: 'Thank you for donating!';
    padding-left: 5px;
    text-shadow: 1px 1px #559bb4;
    color: #ffffff;
    text-align: left;
    background: #5eaec2; /* Old browsers */
    background: -moz-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #5eaec2),
      color-stop(26%, #6fcde4),
      color-stop(100%, #6fcde4)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      top,
      #5eaec2 0%,
      #6fcde4 26%,
      #6fcde4 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* IE10+ */
    background: linear-gradient(to bottom, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5eaec2', endColorstr='#6fcde4',GradientType=0 ); /* IE6-9 */
  }
  .onoffswitch-switch {
    display: block;
    width: 60px;
    margin: 0px;
    height: 26px;
    background: #5680ac; /* Old browsers */
    background: -moz-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #5680ac),
      color-stop(100%, #1e3a5c)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* IE10+ */
    background: linear-gradient(to bottom, #5680ac 0%, #1e3a5c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5680ac', endColorstr='#1e3a5c',GradientType=0 ); /* IE6-9 */
    border: 2px solid #5a7ea9;
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .onoffswitch-checkbox.checked ~ .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox.checked ~ .onoffswitch-label .onoffswitch-switch {
    left: 158px;
  }
  div.form_element.onoffswitch {
    position: relative;
    width: 222px;
  }
  @include breakpoint($medium-up) {
    #charity-header {
      padding-left: 20px;
    }
  }
  @media (max-width: 768px) {
    div.content {
      padding: 0px 10px;
    }
    div.donation_amounts_panel {
      // Margins for donation_amount_panel have to be lower than it's parent, to be aligned properly on mobile
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

div.donation_amounts_panel {
  #select-amount {
    float: left;
    @include breakpoint($medium-up) {
      padding-left: 20px;
    }
    @include breakpoint($small-down) {
      display: none;
    }
  }
  #select-amount-mobile {
    float: left;
    @include breakpoint($small-down) {
      padding-top: 2px;
      font-size: 9px;
      //font-size: 2vw;
    }
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  label {
    font-family: $brandon-text !important;
    padding-left: 5px;
    @media only screen and (max-device-width: 470px) {
      padding-left: 2px;
      font-size: 10px;
    }
  }
  .donation-group {
    @media only screen and (max-device-width: 359px) {
      white-space: nowrap;
    }
  }
  h2 {
    // background-color: #E0F3F9 !important;
    background-color: $color-gray-light-alt !important;
    @include breakpoint($small-down) {
      letter-spacing: 0.1em !important;
      text-align: right !important;
    }
    @media only screen and (max-device-width: 359px) {
      overflow: auto;
      text-align: left !important;
    }
  }
}

#donation-order-summary {
  @include breakpoint($small-down) {
    text-transform: capitalize;
  }
}

.checkout__sidebar {
  section.donations {
    padding: 0px 19px 13px 19px;
    @media (max-width: 768px) {
      padding: 0px;
    }
  }
  .product {
    padding-top: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .recommended-product-items .recommended-item .product_name a {
    border-style: none !important;
    border-width: 0px !important;
    padding: 0px !important;
    text-decoration: none;
    word-break: break-word !important;
  }
}

@media (min-width: 1025px) {
  .checkout__sidebar {
    .tab {
      padding: 10px 6px;
      font-size: 10px;
    }
  }
}

#terms-conditions h2 {
  text-transform: uppercase;
  font-weight: bold;
}

/*** ApplePay integration ***/

/* User not logged in */

.viewcart-buttons-panel {
  &.applepay {
    &.panel {
      .content {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .continue-buttons {
      .continue-checkout,
      .paypal-checkout {
        display: inline-block;
      }
      .continue-checkout {
        float: right;
      }
      .or {
        float: right;
        display: inline;
        margin-top: 12px !important;
      }
      &.applepay-enabled {
        float: right;
        position: relative;
        width: 157px;
        height: 120px;
        padding: 0 !important;
        margin-bottom: 30px;
        text-align: center;
        .continue-checkout,
        .paypal-checkout {
          position: absolute;
          display: block !important;
          float: none;
          width: 100%;
        }
        .continue-checkout {
          top: 0;
        }
        .paypal-checkout {
          bottom: 0;
        }
        .or {
          position: absolute;
          top: 27px;
          float: none;
          display: block;
          width: 100%;
          text-align: center;
          margin: 12px 0 !important;
        }
      }
    }
    .applepay-integration-wrapper {
      float: right;
      width: 100%;
      padding: 10px 13px;
      border: 1px solid #b7b7b7;
      background: #ebebeb;
      &.applepay-error {
        border-color: #e61f26;
        background: #ffe2e4;
      }
      div.applepay-button-container {
        width: 65%;
        .divide {
          position: relative;
          padding-right: 45px;
          input[type='checkbox'] ~ label::before {
            position: absolute;
            top: calc(50% - 4px);
            right: 10px;
            width: 15px;
            height: 15px;
          }
          .label-content {
            a {
              display: inline !important;
            }
          }
        }
      }
      span.applepay-button-container {
        width: 35%;
        text-align: center;
        .apple-pay-button {
          display: block !important;
        }
      }
      .applepay-button-container {
        display: inline-block;
        vertical-align: middle;
        text-align: justify;
        .apple-pay-button {
          overflow: hidden;
          text-indent: 100%;
          white-space: nowrap;
          -webkit-appearance: -apple-pay-button;
          -apple-pay-button-style: black;
        }
        .apple-pay-button.button-style-buy {
          -apple-pay-button-type: buy;
        }
        .apple-pay-button.button-style-set-up {
          -apple-pay-button-type: set-up;
        }
      }
      @media (max-width: 767px) {
        .continue-shopping {
          position: static;
          float: left;
          display: block;
          width: auto;
        }
      }
      @media (min-width: 40em) and (max-width: 641px) {
        span.applepay-button-container {
          display: block;
          float: left;
          padding-left: 0;
        }
      }
      @media (max-width: 40em) {
        .applepay-button-container {
          display: block;
          min-width: 100%;
          padding-left: 0;
          .btn {
            display: block;
            width: 100%;
          }
        }
      }
    }
    .js-applepay-error-container {
      color: #eb3473;
      padding-top: 10px;
      padding-left: 5px;
      float: left;
    }
  }
  .continue-buttons {
    .paypal-smart-button {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10%;
      @include breakpoint($landscape-up) {
        margin: 0;
      }
    }
    .checkout-btn {
      height: 43px;
      line-height: 1.6;
      @include breakpoint($medium-up) {
        line-height: 0.2;
      }
    }
  }
}
/* 640px */

@media (max-width: 40em) {
  .viewcart-buttons-panel {
    &.applepay {
      &.panel {
        .content {
          float: none;
        }
      }
      .continue-shopping {
        margin-bottom: -1em !important;
      }
      .continue-buttons {
        &.applepay-enabled {
          width: 100%;
          height: 120px;
          .continue-checkout,
          .paypal-checkout {
            float: none !important;
            width: 100% !important;
            text-align: center;
          }
          .continue-checkout {
            width: 100%;
            margin-bottom: 7px !important;
            text-align: center;
          }
          .or {
            position: absolute;
            top: 23px;
            width: 100% !important;
          }
        }
      }
      .applepay-integration-wrapper {
        display: table;
        table-layout: fixed;
        float: none;
        clear: both;
        width: 100%;
        margin: 0;
        section.divide {
          padding-top: 10px;
        }
        div.applepay-button-container {
          display: table-footer-group;
          float: none;
          width: auto;
          text-align: left;
          .error {
            margin: 5px 0 !important;
            background: transparent !important;
          }
        }
        span.applepay-button-container {
          display: table-header-group;
          float: none;
          width: auto;
        }
      }
    }
  }
}
/* User logged in */

.elc-user-state-logged-in {
  .viewcart-buttons-panel {
    &.applepay {
      padding-left: 0;
      .continue-shopping {
        margin: 0;
        padding: 5px;
      }
      .continue-buttons {
        &.applepay-enabled {
          float: right;
          position: static;
          width: auto;
          height: auto;
          margin-bottom: 0;
          font-size: 0;
          line-height: 0;
          .or {
            display: none;
          }
          .paypal-checkout,
          .continue-checkout {
            position: static;
            display: inline-block !important;
            float: none;
            width: auto;
            vertical-align: top;
          }
          .paypal-checkout {
            height: 35px;
            margin-right: 4px;
            img {
              height: inherit;
            }
          }
        }
      }
      .applepay-integration-wrapper {
        width: auto;
        background: transparent;
        border: 0 none;
        margin-right: 4px;
        padding: 0;
        div.applepay-button-container {
          display: none;
        }
        span.applepay-button-container {
          width: 140px;
          height: 35px;
        }
      }
      @media (max-width: 1024px) {
        padding-left: 10px;
        .continue-shopping {
          position: static;
          width: auto;
        }
        .continue-buttons {
          &.applepay-enabled {
            width: 140px;
            .paypal-checkout,
            .continue-checkout {
              display: block !important;
              margin: 0 0 20px !important;
            }
          }
        }
        .applepay-integration-wrapper {
          clear: both;
          margin: 0;
        }
      }
      @media (max-width: 40em) {
        .continue-shopping {
          margin-bottom: 1em !important;
        }
        .applepay-integration-wrapper {
          display: block;
          float: right;
          clear: both;
          width: auto;
          margin: 0;
          section.divide {
            padding-top: 10px;
          }
          .applepay-button-container {
            .btn {
              width: 135px;
              height: 35px;
            }
          }
          div.applepay-button-container {
            display: none;
          }
          span.applepay-button-container {
            display: block;
            float: none;
            width: auto;
          }
        }
      }
      @media (max-width: 480px) {
        .continue-shopping {
          float: none;
          width: 100%;
          text-align: center;
        }
        .continue-buttons {
          &.applepay-enabled {
            float: none;
            width: auto;
            margin: 0 auto;
            .paypal-checkout,
            .continue-checkout {
              width: 135px !important;
              margin: 0 auto 20px !important;
            }
          }
        }
        .applepay-integration-wrapper {
          float: none;
          margin: auto;
          .applepay-button-container {
            .btn {
              margin: auto;
            }
          }
        }
      }
    }
  }
}
/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  margin-bottom: 10px;
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: none;
    color: #{$color-bundle-gray};
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    color: $color_fuscous_gray;
    border: 1px solid $color-grey-scale;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
    .appt-book & {
      @include breakpoint($medium-up) {
        right: 0%;
        left: inherit;
        text-align: justify;
      }
    }
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color_fuscous_gray transparent;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: $color_fuscous_gray transparent transparent transparent;
    .appt-book & {
      @include breakpoint($medium-up) {
        right: 19%;
        left: inherit;
      }
    }
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
  &.tooltip-btm-left-margin {
    margin: 0 0 14px 20px;
  }
  &.tooltip-right-margin {
    margin: 0px 0 14px 0;
  }
  &.tooltip-top-margin {
    margin: 15px 0px 0px 0px;
  }
  &.text-right {
    text-align: right;
  }
  &.text-left {
    text-align: left;
  }
  &.profile_tip {
    margin: 10px 20px 0;
    .tooltiptext-over {
      width: 90%;
    }
  }
  .appt-book & {
    @include breakpoint($medium-up) {
      text-align: $rdirection;
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .confirm-container {
        overflow: visible;
      }
      .book-appt-container {
        .registration {
          &__terms {
            width: 100%;
          }
          &__email-list {
            height: auto;
          }
        }
      }
    }
  }
}

.privacy_link_under,
.tip_spacing {
  padding: 1em;
}

.site-email-signup {
  .site-email-signup__form {
    overflow: visible;
  }
  .info-link,
  #email-notice {
    display: none;
  }
}
/* Checkout Sticky Button */
@include breakpoint($small-down) {
  .sticky-footer-visible {
    padding-bottom: 90px;
  }
  .sticky-container {
    position: fixed;
    #{$ldirection}: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    overflow: hidden;
    z-index: 9999;
  }
  .sticky-footer {
    height: 90px;
    background-color: $color-white;
    bottom: 0;
    position: absolute;
    width: 100%;
    border-top: 1px solid $color-light-gray;
    margin: 0;
    padding: 25px 5px;
    text-align: center;
    & a.sticky-btn,
    & input.sticky-btn {
      display: block;
      height: 41px;
      line-height: 3;
      background-color: $color-black;
      margin: 0 auto;
      padding: 0;
      text-align: center;
      width: 90%;
      &:focus {
        background-color: $color-pink;
      }
    }
    &__total {
      float: #{$ldirection};
      font-weight: bold;
    }
    &__terms {
      float: #{$rdirection};
      width: 83%;
      border-#{$ldirection}: 1px solid $color-black;
      .overlay-wrapper {
        display: inline-block;
      }
      & a {
        white-space: nowrap;
      }
    }
    &__review-buttons {
      padding: 13px;
      &.show-buttons {
        padding: 25px 5px;
      }
    }
    &--samples-buttons {
      @include swap_direction(padding, 5px);
      height: 100px;
    }
    &__samples-buttons-text {
      @include swap_direction(margin, 8px 0 0 0);
      @include swap_direction(padding, 0 0 10px 0);
      font-weight: 700;
      font-size: 15px;
      text-transform: capitalize;
    }
    &__clear-reset {
      @include swap_direction(padding, 10px);
      border: none;
      background: none;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: underline;
    }
    &__gift-section {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
    }
    & &__samples-continue {
      width: 48%;
    }
  }
  .offerspick__header__remove {
    display: none;
  }
}

.offerspick {
  .button--outline {
    background-color: #{$color-black};
    color: #{$color-white};
    border: 2px solid #{$color-black};
  }
  &__offer {
    &.is_selected {
      .offerspick__button--remove {
        background-color: #{$color-black};
        border: 2px solid #{$color-black};
      }
    }
  }
}
