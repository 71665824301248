.cart-item {
  &__desc-content {
    @include breakpoint($small-down) {
      @include clearfix;
      float: left;
      .checkout__sidebar & {
        width: 100%;
      }
    }
  }
  &__desc {
    &-info {
      .color {
        @include breakpoint($small-down) {
          margin: 4px 0 0;
        }
      }
    }
  }
  &__desc .remove-sample,
  &__remove-form a {
    @include breakpoint($small-down) {
      font-size: 11px;
    }
  }
} // /cart-item

.cart-item {
  .checkout__sidebar & {
    border-bottom: none;
    padding-bottom: 0;
    .cart-item__total {
      @include breakpoint($small-down) {
        position: static;
        float: right;
        width: 20%;
        margin-right: 18px;
      }
    }
  }
  &__thumb {
    .checkout__content & {
      @include breakpoint($medium-up) {
        &-image {
          padding: 0;
        }
      }
    }
  }
  &__qty {
    .sample & {
      @include breakpoint($medium-up) {
        .checkout__content & {
          padding-left: 12px;
        }
      }
      .cart-item__qty-label {
        float: none;
      }
    }
    @include breakpoint($small-down) {
      margin-top: 0;
      padding-top: 0;
      &-label,
      & .qty-label {
        .checkout__sidebar & {
        }
      }
    }
    .checkout__sidebar & {
      @include breakpoint($small-down) {
        float: left;
        width: 10%;
        padding-top: 0;
        text-align: left;
      }
      @include breakpoint($medium-up) {
        position: absolute;
        top: 25px;
        right: 0;
      }
    }
    select,
    a.selectBox,
    .selectbox & {
      @include breakpoint($small-down) {
        min-width: 50px;
        width: 50px;
        margin-top: 5px;
      }
    }
    .checkout__sidebar & * {
      display: inline-block;
    }
    a {
      color: $color-black;
      border-bottom: 1px solid $color-black;
    }
  }
  &__price {
    .price_label {
      display: none;
    }
  }
  &__desc,
  &__qty,
  &__total {
    float: left;
    clear: none;
  }
  &__size,
  &__total,
  &__total a,
  &__desc-info .shade,
  & .color {
    @include breakpoint($small-down) {
      font-size: 14px;
    }
  }
}

.viewcart {
  .cart-item {
    &__total {
      @include breakpoint($small-down) {
        font-family: 'Brandon Text';
        text-align: right;
      }
    }
  }
}

.linethrough {
  text-decoration: line-through;
}

.viewcart-panel.edit .price_sm {
  display: none;
}
