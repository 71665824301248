// offers
.offer-code-panel {
  input[type='submit'].btn {
    .checkout & {
      @include breakpoint($small-down) {
        background-color: #ff4661;
      }
    }
  }
  input[type='text'] {
    @include breakpoint($medium-up) {
      width: 75%;
    }
  }
}

.promo-panel {
  .bridal_bag_charm {
    border: none;
    img {
      border: none;
    }
  }
  img {
    height: 100%;
  }
}
