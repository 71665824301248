.checkout-confirmation-page {
  @include breakpoint($medium-up) {
    margin-bottom: 0;
  }
  .registration-panel {
    .messages {
      @include breakpoint($medium-up) {
        padding: 0 20px;
      }
    }
    .registration-form-container {
      @include breakpoint($medium-up) {
        width: 44%;
        padding: 0 10px;
      }
      .form-item {
        .title,
        &.password .label {
          display: block;
          @include h16;
          font-size: 12px;
        }
      }
      .password {
        .label {
          margin-bottom: 15px;
        }
        .password-field {
          &__info {
            &::before {
              top: 33%;
              right: 88%;
            }
          }
        }
      }
    }
    input[type='checkbox']:not(:checked) {
      &.checkbox_error ~ label {
        color: #e20400;
      }
    }
  }
  .sign-in-confirmation-panel {
    &__title {
      @include checkout-section-header;
    }
    padding-bottom: 0px;
    &__content,
    .content {
      @include breakpoint($medium-up) {
        margin: 0 20px;
        .form-item {
          width: 43%;
          label {
            display: block;
            visibility: visible;
          }
        }
        .password {
          label {
            display: block;
            visibility: visible;
            margin: 15px 0;
          }
          input {
            margin-bottom: 10px;
          }
        }
        .email-address {
          label {
            display: block;
          }
        }
        .submit {
          margin-bottom: 15px;
        }
      }
    }
  }
  .guarantee-panel {
    clear: both;
    @include breakpoint($medium-up) {
      padding: 20px 20px 0 20px;
      border-top: 1px solid $color-gray-border;
      p {
        padding-right: 20px;
      }
    }
    &__title {
      @include checkout-header;
    }
  }
  .confirmation-panel {
    padding-bottom: 20px;
  }
}

.confirmation-panel {
  &__title {
    margin-right: 20px;
    header & {
      @include checkout-section-header;
    }
  }
  &__subtitle {
    margin: 18px 0 25px;
  }
  &__print-buttons {
    .btn {
      &,
      .checkout & {
        @include button--secondary;
        background-color: $color-black;
        &:hover {
          background-color: $color-black;
        }
      }
    }
  }
}
