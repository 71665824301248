//colors

$color_gallery: #eee;
$color_wild_sand: #f5f5f5;

//original selectors
//#bt_settings div.bt-content div.slide, #bt_settings div.bt-content ul.slide-toggle
@mixin tool-wrapper {
  position: relative;
  overflow: hidden;
  width: 525px;
}

#bt_settings {
  display: block !important;
  div {
    &.bt-content {
      border: 1px solid $color-black;
      padding: 15px;
      background-color: $color-white;
      z-index: 1001;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 1.5em;
      border-color: $color-black;
      border-width: 2px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 6px;
      div {
        &.bt-intro {
          display: block !important;
          h2 {
            padding: 0;
            margin: 0 0 1em 0;
            border: none;
            text-transform: none;
            color: $color-black;
            background: none;
          }
          div.bt-intro-copy {
            display: block !important;
            p {
              margin-top: 1em;
            }
          }
        }
        &.slide {
          @include tool-wrapper;
          margin: 20px 0;
          display: block !important;
        }
        &.mask {
          position: absolute;
          overflow: hidden;
          top: 0;
          left: 0;
          width: 0;
          border-right: 1px solid $color_gallery;
          display: block !important;
          ul.slide-toggle {
            background-image: url(/media/export/images/cookies/all-on.png);
          }
          div.drag {
            position: absolute;
            bottom: 12px;
            display: block;
            right: 70px;
            width: 26px;
            height: 11px;
            background: transparent url(/media/export/images/cookies/icon_arrow.png) 50% 100% no-repeat;
          }
        }
      }
      .bt-privacy-info {
        display: none;
        overflow: hidden;
        &.selected {
          display: block !important;
        }
        h3 {
          padding: 1em 1em 1em 40px;
          background-position: 10px 50%;
          background-repeat: no-repeat;
          background-color: $color_wild_sand;
        }
        div {
          width: 48%;
          margin-right: 2%;
          float: left;
          display: block !important;
          &.bt-privacy-will {
            h3 {
              background-image: url(/media/export/images/cookies/icon_safe_lock.png);
            }
            ul li {
              background-image: url(/media/export/images/cookies/icon_safe_thick.png);
            }
          }
          &.bt-privacy-willnot {
            h3 {
              background-image: url(/media/export/images/cookies/icon_unsafe_lock.png);
            }
            ul li {
              background-image: url(/media/export/images/cookies/icon_unsafe_thick.png);
            }
          }
          ul {
            list-style-type: none;
            padding-left: 0;
            margin: 1em;
            li {
              margin: 0.8em 0;
              padding-left: 30px;
              background-position: 0 50%;
              background-repeat: no-repeat;
            }
          }
        }
      }
      ul.slide-toggle {
        @include tool-wrapper;
        list-style: none;
        margin: 0;
        padding: 0;
        background: transparent url(/media/export/images/cookies/all-off.png) 50% 100% no-repeat;
        background-size: contain;
        li {
          cursor: pointer;
          float: left;
          width: 175px;
          height: 148px;
          &.middle {
          }
          &.selected {
          }
          span {
            display: block;
            font-weight: bold;
            text-align: center;
            margin: 5px;
            display: none;
          }
        }
      }
    }
    &.bt-content-mobile {
      display: block !important;
      div {
        &.mask {
          ul.slide-toggle {
            background-image: url(/media/export/images/cookies/m_all-on.png);
          }
          div.drag {
            right: 36px;
            right: 30px;
            bottom: 11px;
          }
        }
        &.slide {
          margin: 20px auto;
          width: 288px;
          width: 246px;
        }
      }
      ul.slide-toggle {
        background-image: url(/media/export/images/cookies/m_all-off.png);
        width: 288px;
        width: 246px;
        li {
          width: 96px;
          height: 159px;
          width: 82px;
          height: 136px;
          span {
          }
        }
      }
      .bt-privacy-info div {
        width: 100%;
        float: none;
      }
    }
    &.bt-content-desktop {
      padding: 2em;
      height: 100%;
      display: block !important;
    }
  }
}

#bt_notification div {
  &.bt-content {
    position: relative;
    border: 1px solid $color-black;
    padding: 15px;
    background-color: $color-white;
    z-index: 1001;
    font-size: 12px;
    line-height: 16px;
    border-color: $color-black;
    border-width: 10px;
    padding: 20px 25px 20px 20px;
    a {
      color: $color-black;
      font-weight: bold;
      &.bt-close-link {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        width: 15px;
        height: 15px;
        background: url(/media/export/images/cookies/close_x.png) 50% 50% no-repeat;
        text-indent: -9999em;
      }
    }
  }
  &.bt-bottom-right {
    position: fixed;
    bottom: 0;
    right: 10px;
    width: 250px;
    border-bottom-width: 0;
  }
  &.bt-bottom-full {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-width: 0;
  }
  &.bt-content-mobile {
    padding: 10px;
    a.bt-close-link {
      display: none;
    }
  }
}

.customer-service-section div#bt_settings {
  display: block;
  div {
    display: block;
  }
}

.tealium {
  display: none !important;
}
