$mobile-width: 480px;

.site-email-sms-signup {
  &__header {
    width: 100%;
    h1 {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: #{$mobile-width}) {
        margin-bottom: 25%;
      }
    }
  }
}
