@mixin address-book-button {
  @include breakpoint($small-down) {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}
.add-payment-page {
  .account-utilities {
    float: left;
    margin-right: 32px;
  }
  &__content {
    .payment-book-page__content {
      width: auto;
      overflow: hidden;
    }
  }
  &__header {
    // TODO: Consolidation candidate (see address book)
    @include breakpoint($small-down) {
      @include h13;
    }
    @include breakpoint($medium-up) {
      @include h19;
      text-indent: $account-indent;
      margin: 4px 0 17px 0;
      padding-bottom: 16px;
      border-bottom: 2px solid $color-black;
    }
  }
  #edit-payment-page_header {
    border-bottom: 1px solid #000000;
    font-size: 14px;
  }
  &__subheader {
    @include h8;
  }
  .payment_method &__subheader {
    @include h8;
    border-bottom: 1px solid $color-black;
    border-top: 1px solid $color-gray-light;
    padding: 15px 0;
    margin-bottom: 7px;
  }
  .payment-book__button {
    border-bottom: 1px solid #ccc;
    margin: 30px 0 23px;
    padding-bottom: 20px;
    a {
      background-color: #ff4661;
    }
  }
  .payment-info {
    margin: 0 0 40px $account-indent;
    width: 95.43%;
    .payment-book__header {
      margin-bottom: 6px;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
    &__header {
      @include h16;
      margin: 34px 0 15px;
      border-bottom: 0px;
      margin-bottom: 0px;
      text-indent: 0px;
    }
    .controls {
      margin: 14px 0 14px;
      .default-payment {
        @include button--outline-thin;
      }
      .delete-payment {
        margin-left: 20px;
      }
    }
    .payment-item__default-address {
      margin-top: 6px;
    }
    .payment-book__other {
      border-bottom: $account-separator;
    }
    .payment-book-item__other {
      border-top: $account-separator;
      padding: 10px 0;
    }
  }
  .payment-form {
    width: auto;
    &__header {
      @include address-form-header;
      text-transform: none;
    }
    &__item {
      padding: 7px 0;
      label {
        @include brandon-text;
        @include h8;
        font-size: 12px;
        line-height: 20px;
        text-transform: none;
      }
      &.card_type_container {
        @include breakpoint($medium-up) {
          width: 40%;
        }
      }
    }
    &.card_number_container input {
      width: 185px;
    }
    &.expiration_date_container a {
      width: 105px;
      display: inline-block !important;
    }
    &.expiration_date_container p {
      display: inline;
      position: relative;
      bottom: 10px;
    }
    &.cvn_payment_container input {
      width: 63px;
    }
  }
  .payment_address {
    padding-bottom: 29px;
  }
  .payment_method {
    padding-bottom: 29px;
  }
  @media screen and (max-width: 780px) {
    .payment_address {
      .address_controls {
        width: 90%;
        select {
          width: 100%;
        }
      }
    }
  }
}

.address_controls {
  width: 760px;
  select {
    width: 760px;
  }
}

.address-book {
  &-page {
    .address-book__content {
      margin-top: 14px;
      .payment-details {
        img {
          margin: 5px 0;
        }
      }
    }
  }
  &__button {
    @include address-book-button;
  }
}

.address-form {
  &__header {
    font-size: 24px;
  }
}

.site-email-sms-signup {
  .site-email-sms-signup__content {
    .site-email-sms-signup__error {
      @include breakpoint($medium-up) {
        margin-top: 80px;
      }
    }
  }
}

.payment {
  &-info {
    &-page {
      @include address-book-page;
    }
    &__header {
      @include breakpoint($small-down) {
        @include h13;
        .payment-info-page & {
          // do some resets of the imported styles from above, for mobile
          text-indent: 0;
          padding-bottom: 0;
          margin-bottom: 30px;
          border-bottom: none;
        }
      }
      @include breakpoint($medium-up) {
        @include h19;
        text-indent: $account-indent;
        margin: 4px 0 17px 0;
        padding-bottom: 16px;
        border-bottom: 2px solid $color-black;
      }
    }
    &__button {
      @include address-book-button;
    }
  }
  &-form {
    @include breakpoint($small-down) {
      padding: 0 10px 10px;
      margin-left: 0;
    }
    input[type='submit'] {
      background-color: #ff4661;
    }
    &__item {
      @include breakpoint($small-down) {
        .selectbox {
          white-space: normal;
          width: 100%;
        }
      }
      .card_number_field {
        width: 40%;
      }
      &.expiration_date_container a {
        display: inline-block !important;
        width: 20%;
        float: left;
        margin-right: 10px;
        margin-bottom: 7px;
      }
      &.cvn_payment_container {
        clear: both;
      }
      .seperator {
        margin: 0 10px;
        float: left;
        line-height: 37px;
      }
    }
  }
}

.address,
.payment {
  &-form {
    .required-note {
      * {
        margin-bottom: 5px;
      }
    }
  }
}

///
/// Layout of signin panel
///
.account__new-account,
.account__return-user {
  @include breakpoint($small-down) {
    input[type='password'] {
      margin-top: 10px;
    }
  }
}

.return-user__item,
.new-account__item,
.request-form__item,
.sent-info__text,
.password-reset__item {
  margin-bottom: 0.8em;
}

.password-reset__item {
  .field {
    display: block;
  }
}

.password-reset__text {
  margin: 10px 0px;
}

.signin-page {
  max-width: 1024px;
  margin: 25px auto;
}

.account-page {
  &__content {
    .section-header {
      &:before {
        background: none;
      }
    }
    .account-profile {
      &__email {
        word-wrap: break-word;
      }
    }
  }
}

.password-request-page {
  #customer-service {
    .customer-service-header {
      @include customer-service-header(50px);
    }
    .customer-service-content {
      p {
        @include customer-service-content(50px);
      }
    }
  }
  &__header {
    text-indent: 0;
    padding-left: 20px;
    width: 100%;
  }
  .password-request {
    &__text {
      width: 100%;
    }
    .request-form {
      &__item {
        &.label {
          font-weight: bold;
        }
      }
    }
  }
}

.password-sent-page {
  #customer-service {
    .customer-service-header {
      @include customer-service-header(0px);
    }
    .customer-service-content {
      p {
        @include customer-service-content(0px);
      }
    }
  }
  &__header {
    width: 100%;
  }
  .password-sent {
    &__text {
      width: 100%;
      .email-address {
        font-weight: bold;
      }
    }
  }
}

.password-reset-page {
  .password-field__info {
    margin-top: 4px;
    width: 200px;
    &::before {
      top: 18%;
      right: 97%;
    }
  }
  #customer-service {
    .customer-service-header {
      @include customer-service-header(0px);
    }
    .customer-service-content {
      p {
        @include customer-service-content(0px);
      }
    }
  }
  &__header {
    width: 100%;
  }
  .password-reset {
    &__text {
      width: 100%;
    }
  }
}

.account {
  .profile-page {
    .profile-page__content {
      #profile_preferences {
        .newsletter-info {
          fieldset.fs {
            margin: 0 0 0 10px;
            float: left;
            width: 100%;
            padding: 10px;
            p {
              margin-bottom: 15px;
            }
          }
          input.form-submit {
            margin-top: 5px;
          }
          &__text--new {
            margin-bottom: 0;
          }
          .content-block {
            overflow: visible;
          }
        }
      }
    }
  }
}

.add-payment-page {
  .add-payment-page__content {
    .payment_form {
      .payment_method {
        p {
          margin: 5px 0px 5px 0px;
        }
        .form_element {
          margin: 15px 0px 0px 0px;
          label {
            font-weight: bold;
          }
        }
        .default_payment_container {
          label {
            font-weight: normal;
          }
        }
      }
      .payment_address {
        .address_controls {
          li a {
            border: 1px solid;
          }
        }
      }
    }
  }
}

#colorbox {
  .address-delete-confirm {
    h2 {
      margin-bottom: 15px;
      border-bottom: 1px solid;
      padding-bottom: 15px;
      line-height: 25px;
    }
    div {
      margin-bottom: 10px;
    }
  }
  #address-form-popover {
    #QAS_NODE {
      select[name='QAS_PICKLIST'] {
        height: 32px;
        border: 1px solid black;
        width: 100%;
        margin: 10px;
      }
      .form_element {
        &.qas_submit_container {
          margin: 10px;
        }
      }
    }
    #display_manual_address {
      padding: 10px;
    }
    #continue-btn {
      margin: 10px;
    }
    .manual_address_container {
      padding: 0;
    }
    .default-shipping-container {
      span {
        &.label-content {
          display: block;
          margin: -20px 0 0 20px;
        }
      }
    }
  }
}

.past-purchases-page {
  .past-purchases__content {
    .order-info {
      .shipments-list {
        .products {
          li {
            .product-item {
              .product-info__spp-status {
                padding: 10px;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

body.en_GB .favorite-actions {
  top: 60px;
}

.artist-picks-page {
  .artist-picks-page__chats {
    .product-info {
      .product-info__sku-info {
        height: 50px;
      }
      .product-info__shade {
        height: 19px;
      }
      .product-info__price {
        float: right;
        &.price_per_ml {
          width: 100%;
          text-align: right;
          padding-right: 20px;
          float: right !important;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .past-purchases-page .product-info__price,
  .favorites-page .product-info__price,
  .wishlist-page .product-info__price,
  .artist-picks-page .product-info__price {
    width: 11%;
  }
  .favorites-page .product-info__actions,
  .wishlist-page .product-info__actions {
    width: 25%;
  }
}

.payment-delete-confirm {
  h3.gc_header {
    margin: 30px 0 15px 38px !important;
    border: none !important;
    padding-bottom: 0px !important;
  }
  P {
    text-align: center !important;
  }
  strong {
    font-size: 26px;
  }
}

#QAS_NODE.registration_pro select,
#QAS_NODE.registration_pro .qas_submit_container {
  margin: 0px 20px 20px 20px;
  min-width: auto !important;
}

#QAS_NODE h3 {
  display: none;
}

.registration-pro-page__content {
  position: relative;

  .profile-info {
    .selectBox-dropdown {
      &:hover {
        color: $color-cta-hover;
      }
    }
  }
  .pro-member-info {
    #pro-level-form {
      a {
        color: $color-gray-link;
      }
    }
  }
  .newsletter-info {
    input[type='submit'] {
      margin-#{$ldirection}: 40%;
    }
  }
}

.registration-pro-page__required-container {
  top: 0px;
  right: 0px;
}

.pro_level_criteria ul {
  list-style-type: disc;
  padding-left: 20px;
  padding-bottom: 20px;
}

.pending-pro_registration_steps,
.pending-pro_print,
.pending-pro_level,
.pending-pro_mail_processing {
  padding-left: 20px;
}

.pro-attributes-masterclass-attended {
  padding-left: 12px;
}

.masterclass_form_container {
  padding-left: 30px;
}

.order-totals td {
  text-align: right;
}

#form--address--field--PHONE1 {
  width: 84%;
}

.pending-pro .pro-membership-option label {
  width: auto;
}

.optional-info__birthday-select {
  vertical-align: top;
}

.optional-info__birthday-select .field.error {
  height: 32px;
}

#form--errors--payment_add.error_messages_display,
#form--errors--payment_edit.error_messages_display {
  li {
    margin-left: 0px;
  }
}
/* Password GDPR validation */
.cms-password-field,
.password-field {
  position: relative;
  &__info {
    border: 1px solid $color-gray-light;
    margin-top: -16px;
    &::before {
      content: '';
      position: absolute;
      top: 16%;
      right: 91%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-black transparent;
    }
    .cms-password-field__rules,
    .password-field__rules {
      padding-left: 20px;
      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 3px 0;
        color: $color-black;
        transition: 0.2s;
        white-space: nowrap;
        line-height: 1.5;
        &.pass {
          color: $color-green;
          &::before {
            color: $color-green;
            content: '\2713';
            text-shadow: 0 0 8px rgba($color-black, 0.5);
          }
        }
        &::before {
          content: '\2715';
          display: inline-block;
          color: $color-red;
          font-size: 1em;
          line-height: 0;
          margin: 0 6px 0 0;
          transition: 0.2s;
        }
      }
    }
  }
}

.cms-password-field {
  input[type='text'] {
    height: 45px;
    line-height: 2.5;
  }
  .cms-password-field__info {
    margin-top: -14px;
    width: 188px;
    &::before {
      top: 17%;
    }
  }
}

.account__section {
  .return-user {
    &__fieldset {
      .sign-in-page {
        &__submit {
          top: 100%;
        }
      }
      a {
        color: #{$color-bundle-gray};
      }
    }
  }
  .account {
    &__new-account {
      .new-account {
        &__item {
          input[type='password'],
          input[type='text'] {
            margin-bottom: 20px;
          }
          .label-content {
            a {
              color: #{$color-bundle-gray};
            }
          }
        }
      }
    }
  }
}

.profile-info {
  .password-field {
    &__info {
      margin-top: 4px;
      &::before {
        top: 18%;
      }
    }
  }
}

.selectBox-options {
  li {
    a {
      &:hover {
        color: $color-cta-hover;
      }
    }
  }
}
