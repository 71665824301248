#csr_header {
  top: 90px;
  .csr_table {
    table-layout: auto;
    margin: 0;
  }
  a {
    display: block;
  }
  #csr_info_box {
    border-right: 1px solid $color-red;
  }
  #csr_registered_user_info,
  #cust_cart {
    border-right: 1px solid $color-red;
    line-height: 14px;
  }
  .csr_table {
    td {
      vertical-align: middle;
      padding: 0 20px;
      border: none;
    }
    #csr_dragger {
      cursor: move;
      background: $color-red;
      border: none;
      width: 40px;
    }
  }
}
