#msg-panel {
  background-color: #ff4661;
  padding: 18px;
  margin-bottom: 10px;
}

#msg-panel p {
  color: white;
  margin: 0;
  text-transform: uppercase;
}

.sign-in-panel {
  .messages {
    margin: 0 20px;
    padding: 0 20px;
    background: $color-pink;
    color: $color-white;
    text-transform: uppercase;
    .close_link {
      @include h5;
      color: $color-white;
      float: right;
      margin: 0;
    }
    .single-message:first-child {
      padding-top: 14px;
    }
    .single-message:last-child {
      padding-bottom: 4px;
    }
  }

  // responsive rules for the messages and content within this panel, for mobile
  .messages,
  .content {
    @include breakpoint($small-down) {
      padding: 0 10px; // 10px is the standard content width for the checkout section, for UK (we're matching the messages to this width)
      margin: 0;
    }
  }
  .content {
    @include breakpoint($small-down) {
      margin-top: 10px;
    }
  }
  &.finished .content {
    @include breakpoint($small-down) {
      line-height: 24px;
      margin: 0 0 15px;
    }
  }
  .messages {
    color: #{$color-black};
    @include breakpoint($small-down) {
      margin: 10px 10px 0;
    }
  }
  .checkout__subtitle {
    margin-right: 13px;
  }
  .form-item {
    .label {
      display: block !important;
      visibility: visible;
      @include h8;
      font-size: 12px;
      @include breakpoint($small-down) {
        text-transform: none;
        font-family: $base-font-family;
      }
    }
    input {
      margin: 14px 0;
    }
    .example {
      margin-bottom: 10px;
    }
    p {
      @include breakpoint($small-down) {
        margin-bottom: 15px;
      }
    }
  }
  #forgot-pw-note {
    margin-bottom: 27px;
    a {
      border-bottom: 2px solid;
      padding-bottom: 6px;
      @include h8;
      font-size: 12px;
      text-decoration: none;
      @media (max-width: 768px) {
        letter-spacing: 0px;
      }
    }
  }
  .email-panel__title {
    @include breakpoint($small-down) {
      margin: 4px 0 10px;
      line-height: 18px;
    }
  }
  fieldset {
    @include breakpoint($small-down) {
      display: inline-block;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

///
/// Layout of signin panel
///

.checkout {
  &__new-account,
  &__return-user {
    @include breakpoint($medium-up) {
      padding: 0 $cart-spacing * 2;
      margin: 12px 0 20px 0;
      p {
        margin: 0 0 18px;
        line-height: 1.2em;
      }
    }
  }
  #new-account-h,
  #return-account-h {
    @include breakpoint($small-down) {
      margin-bottom: 0px;
    }
    @include breakpoint($medium-up) {
      margin-bottom: 4px;
    }
  }
  &__return-user {
    @include breakpoint($medium-up) {
      border-left: 1px solid $color-light-gray;
    }
  }
  .panel.collapsed {
    display: none;
  }
}

.checkout .promo-panel .err-pink {
  color: #ff4661;
  margin-top: 15px;
  font-weight: 800;
}

.checkout .promo-panel .err-pink .close_link {
  margin-right: 10px;
}
