/* Brow Kit Page */

/* Desktop */
.bybk {
  .product_collection_hero {
    background-size: cover;
  }
  .gtl__steps__header {
    font-size: 24px;
  }
  .gtl__steps {
    .product-grid {
      margin: auto;
      .product-grid__item {
        height: auto !important;
        margin: auto !important;
      }
    }
  }
  /* Samples */
  .product_collection_hero__content {
    .product_collection_hero__content-item:nth-child(4) {
      .product-thumb--price {
        display: none;
      }
      .sample-grid-samples {
        .product__button {
          &.disabled {
            background-color: $color-gray-light;
            border-color: $color-gray-light;
            color: $color-white;
          }
        }
      }
      a.gtl__steps__header {
        &:hover {
          .icon-arrow_right {
            &::before {
              color: $color-black;
            }
          }
        }
        .icon-arrow_right {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          &::before {
            content: 'UNLOCK SAMPLES';
            color: $color-pink;
            font-size: 14px;
            font-family: 'Brandon Text Bold';
            letter-spacing: 2px;
          }
        }
      }
    }
  }
  /* Samples */
}
/* Desktop */

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 979px) {
  .bybk {
    .gtl__steps__header {
      font-size: 18px;
    }
  }
}
/* Tablets */

/* Mobile */
.device-mobile {
  .bybk {
    .gtl__steps__header {
      font-size: 14px;
      line-height: normal;
      text-transform: capitalize;
      font-weight: bold;
      height: 40px;
      .gtl__steps__name {
        padding-left: 0px;
      }
    }
    .gtl__steps__content {
      .js-quickshop-link {
        span.desktop-text {
          display: block;
        }
        span.mobile-text {
          display: none;
        }
      }
    }
  }
}
/* Mobile */

/* Brow Kit Page */
