$mobile-width: 480px;

.site-email-sms-thank-you {
  padding-bottom: 50px;
  @media (max-width: #{$mobile-width}) {
    padding-bottom: 0px;
  }
  &__header {
    margin-top: 5%;
    width: 100%;
    h1 {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: #{$mobile-width}) {
        margin-bottom: 25%;
      }
    }
  }
  &__footer {
    margin: 10% auto 0;
    text-align: center;
    width: 100%;
    &p {
      font-size: 12px;
    }
  }
  section.copy {
    text-align: center;
    font-size: 18px;
  }
  &.registered {
    footer.site-email-sms-thank-you__footer {
      margin-bottom: 0%;
    }
  }
}
