//One Trust Implementation CSS Changes
.optanon-alert-box-wrapper {
  .optanon-alert-box-bg {
    @include breakpoint($medium-up) {
      .optanon-alert-box {
        &-body {
          margin: 0 40px;
        }
        &-button-container {
          position: relative;
          margin: 5px;
        }
      }
    }
    .optanon-alert-box-button-middle {
      .accept-cookies-button,
      .cookie-settings-button {
        &:active,
        &:hover {
          background: none;
        }
      }
    }
  }
  .optanon-alert-box-bottom-top {
    .optanon-alert-box-corner-close {
      @include breakpoint($medium-up) {
        top: auto;
        bottom: 22px;
        .banner-close-button {
          &:hover {
            background: url('/media/export/images/btns/ot-close-button.svg') !important;
          }
        }
      }
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

.cookie-settings-ot {
  .optanon-show-settings-popup-wrapper {
    @include swap_direction(margin, 0 0 0 20px);
    @include breakpoint($medium-up) {
      max-width: 1024px;
      height: 35px;
      margin: 0 auto 20px;
    }
  }
}

#optanon {
  #optanon-popup-top {
    .optanon-close {
      @include breakpoint($medium-up) {
        &:hover {
          background: url('/media/export/images/btns/ot-close-button.svg') !important;
        }
      }
    }
  }
}
