/* Account signin page */
.outer-wrap .social-login__container .disclaimer {
  text-align: #{$ldirection};
}

.account__section {
  .social-login {
    &__email-opt-in,
    &__email-opt-in__copy,
    .disclaimer,
    .terms-tooltip {
      padding-#{$ldirection}: 20px;
    }
  }
}
/* Checkout */
#main.single {
  .sign-in-panel input {
    width: auto;
    vertical-align: middle;
  }
  .panel label {
    display: inline;
  }
}

.social-login {
  .tooltip {
    @include swap_direction(padding, 10px 0);
    a {
      color: #{$color-bundle-gray};
    }
  }
  a {
    color: #{$color-bundle-gray};
  }
}

.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.fb-overlay-container {
  width: 100%;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  display: inline-flex;
  width: 164px;
  height: 22px;
  position: relative;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.disclaimer {
  position: relative;
  margin-top: 15px;
  padding-top: 20px;
  text-align: #{$ldirection};
  .fb-disclaimer {
    display: block;
    visibility: visible;
    a {
      color: #{$color-bundle-gray};
    }
  }
}

.js-facebook_disclaimer {
  left: 0px;
  opacity: 0;
}

//Book-Appointment Page
.appt-book {
  #appt-book-sign-in-form-container {
    .social-login {
      .tooltip {
        text-align: #{$ldirection};
        .tooltiptext-over {
          max-width: 400px;
          overflow-y: scroll;
          height: 175px;
          @include breakpoint($medium-up) {
            left: 0;
            &::before {
              display: none;
            }
          }
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
/* Confirmation Page */
.confirmation-panel {
  .social-login {
    padding-#{$ldirection}: 0;
    @include breakpoint($medium-up) {
      padding-#{$ldirection}: 20px;
    }
  }
}
/* Gnav */
.site-utils__dropdown--account {
  @include breakpoint($xlarge-up) {
    height: 550px;
    overflow-y: scroll;
  }
}

#colorbox {
  a {
    color: #{$color-bundle-gray};
  }
}
