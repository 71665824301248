.checkout__panel-content {
  .kit-item {
    .cart-item {
      &__desc-container {
        .color {
          @include swap_direction(margin, 14px 0 10px);
          .swatch {
            float: #{$ldirection};
          }
          .shade {
            font-size: 14px;
            line-height: 1.5;
            margin-#{$ldirection}: 33px;
          }
        }
        .engraving {
          &__view-service-sku-price {
            float: #{$rdirection};
            position: relative;
            bottom: 20px;
            #{$ldirection}: 55px;
            @include breakpoint($landscape-up) {
              #{$ldirection}: 75px;
            }
          }
        }
      }
    }
    .engraving-wrapper {
      float: #{$ldirection};
      width: 100%;
      .engraved-term-conditions {
        top: 45px;
        .engraved-link {
          color: #{$color-black};
        }
      }
    }
  }
  .cart-item {
    &__remove-form {
      .engraving-cart-delete {
        width: auto;
      }
    }
    &__desc-info {
      span {
        .overlay-link,
        .overlay-link-cms {
          text-transform: uppercase;
          display: table;
          margin-top: 2%;
          letter-spacing: 1px;
          border-bottom: 2px solid #{$color-gray-link};
        }
      }
    }
    .engraving__cart-control {
      @include swap_direction(margin, 5% 0 2%);
      display: inline-block;
      width: 100%;
      @include breakpoint($landscape-up) {
        float: none;
      }
      .button {
        border: 1px solid #{$color-black};
        background-color: #{$color-white};
        color: #{$color-black};
      }
      span {
        .overlay-wrapper {
          @include swap_direction(padding, 15px 0 0);
        }
        .overlay-link-cms {
          @include swap_direction(padding, 0 0 5px);
          border: none;
        }
        .overlay-link,
        .overlay-link-cms {
          text-transform: uppercase;
          display: table;
          margin-top: 2%;
          letter-spacing: 2px;
          border-bottom: 1px solid #{$color-gray-link};
          font-size: 12px;
          width: auto;
          @include breakpoint($landscape-up) {
            font-size: 13px;
            letter-spacing: 3px;
          }
        }
      }
      .engraving-show-toggle {
        line-height: 1;
        width: 100%;
        font-size: 12px;
        @include breakpoint($medium-up) {
          width: 45%;
        }
        @include breakpoint($landscape-up) {
          width: 40%;
        }
      }
    }
  }
}

.engraving-create {
  display: none;
  h3 {
    font-size: 25px;
    text-transform: none;
    letter-spacing: 0.3px;
  }
}

.engraving-wrapper {
  position: relative;
  @include breakpoint($medium-up) {
    margin-#{$ldirection}: 10px;
  }
  .engraving-create {
    .engraving {
      margin-top: 0;
    }
  }
  .about-graving {
    .overlay-link-cms {
      @include swap_direction(margin, 2% 0 2% 35%);
      border-style: none;
      border-bottom: 1px solid #{$color-gray-link};
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 2% 0 2% 13%);
      }
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 0 0 2% 16%);
        width: auto;
        font-size: 13px;
      }
    }
  }
  .engraving {
    padding: 1em;
    background-color: #{$color-gray-background};
    float: #{$ldirection};
    width: 100%;
    margin-top: 10%;
    .icon {
      display: inline-block;
      width: 35px;
      height: 35px;
      float: #{$rdirection};
      background: url(/media/images/close.png) no-repeat center;
      background-size: 25px;
      &--caret--up {
        display: none;
        background: url(/media/images/arrow_up.png) no-repeat center;
        background-size: 25px;
      }
      &--caret--down {
        background: url(/media/images/arrow_down.png) no-repeat center;
        background-size: 25px;
      }
    }
    .engraved-term-conditions {
      top: 130px;
      #{$ldirection}: 0;
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
    .engraving-accordion-title {
      width: 100%;
    }
    .engraving__title {
      @include swap_direction(margin, 15px 0);
    }
    &__form {
      float: #{$ldirection};
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 55%;
      }
      &-element {
        @include swap_direction(margin, 0 0 1em);
        display: block;
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 65%;
        }
        .engraving__label-intro {
          float: none;
          font-weight: bold;
        }
        .engraving__message-count-container {
          @include swap_direction(margin, 10px 0);
          font-size: 13px;
          text-transform: none;
        }
        &-footer {
          @include breakpoint($landscape-up) {
            @include swap_direction(padding, 3em 1em 0 0);
          }
          .engraving-save {
            display: none;
            @include breakpoint($landscape-up) {
              display: inline-block;
            }
          }
          input[type='submit'] {
            margin-#{$rdirection}: 20px;
            width: 30%;
            border: #{$color-light-gray};
            color: #{$color-white};
            &.engraving-save {
              background: #{$color-black};
              &:hover {
                background: #{$color-cta-hover};
              }
            }
          }
          .engraving-cancel {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 13px;
            text-decoration: none;
            padding-bottom: 6px;
            border-bottom: 1px solid #{$color-black};
          }
          .engraving-preview-enlarge {
            background: #{$color-black};
            border: #{$color-black};
            color: #{$color-white};
            width: 100%;
            margin-bottom: 10px;
            @include breakpoint($landscape-up) {
              margin-#{$rdirection}: 10px;
            }
          }
          .button {
            @include breakpoint($landscape-up) {
              display: none;
            }
            &--disabled {
              background: #{$color-light-gray};
              border: #{$color-light-gray};
              & + input[type='submit'] {
                &.engraving-save {
                  background: #{$color-light-gray};
                  border: #{$color-light-gray};
                }
              }
            }
          }
        }
      }
      &-font {
        width: auto;
      }
      input[type='radio'] {
        display: none;
        & ~ label,
        & ~ .engraving-label-font,
        & ~ .engraving-label-location {
          @include swap_direction(padding, 3px 24px);
          display: inline-block;
          position: relative;
          &:before {
            border: 1px solid #{$color-light-gray};
          }
          &:after {
            color: #{$color-black};
          }
          &:before,
          &:after {
            @include swap_direction(box-shadow, 0 0 0 1px #{$color-black});
            border-radius: 8px;
            border: 2px solid #{$color-white};
            content: '';
            position: absolute;
            top: 5px;
            #{$ldirection}: 0;
            width: 15px;
            height: 15px;
          }
        }
        &:checked {
          & ~ .engraving-label-font,
          & ~ .engraving-label-location {
            &:after {
              background: #{$color-black};
            }
          }
        }
      }
    }
    &__image {
      float: #{$rdirection};
      display: none;
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 40%;
        display: block;
      }
      &-notification {
        font-size: 12px;
        line-height: 1;
      }
      &-title {
        font-weight: bold;
      }
    }
    &__live-preview {
      width: 160px;
      height: 160px;
      position: relative;
      background: #{$color-white};
      margin-top: 5%;
      border: 1px solid #{$color-black};
      @include breakpoint($landscape-up) {
        width: 220px;
        height: 220px;
      }
      &-text {
        text-transform: none;
        position: absolute;
        top: 45%;
        text-align: center;
        width: 100%;
        font-size: 13px;
        #{$ldirection}: 25%;
        font-family: #{$bb-sans};
        color: #{$color-dullwhite-engraved};
        &.engraving {
          &__white-tint {
            color: $color-grey-scale;
            font-size: 12px;
            #{$ldirection}: 26%;
          }
          &__black-brush {
            font-size: 11px;
            #{$ldirection}: 5%;
          }
        }
        &.engraving__view-multiline {
          top: 90px;
        }
        &-line {
          margin-bottom: 5px;
        }
      }
      &-enlarge {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        cursor: pointer;
        padding: 0.5em;
        &.button--disabled {
          background: none;
        }
        img {
          width: 22px;
        }
      }
    }
    &__label {
      border-radius: 4px;
      display: inline-block;
      cursor: pointer;
      text-transform: capitalize;
      &-wrapper {
        margin: 0;
      }
      &-intro {
        @include swap_direction(margin, 5px 10px 0 0);
        float: #{$ldirection};
        @include breakpoint($landscape-up) {
          @include swap_direction(margin, 0 10px 0 0);
        }
      }
    }
    &__type {
      text-transform: capitalize;
      font-size: 15px;
      font-weight: bold;
      margin-top: 5px;
      @include breakpoint($landscape-up) {
        margin-top: 0;
      }
    }
    &__selected {
      border-color: #{$color-black};
      color: #{$color-black};
    }
    &__cart-error-message {
      color: #{$color-red};
      display: none;
    }
    &__edit {
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      &-header {
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
    a {
      &.engraving-toggle-edit {
        margin-bottom: 10px;
        color: #{$color-black};
        border: 1px solid #{$color-black};
        background: #{$color-white};
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 30%;
          margin-#{$rdirection}: 15px;
        }
      }
      &.engraving-delete {
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        color: #{$color-gray-border-dimmer};
        text-decoration: none;
        padding-bottom: 6px;
        border-bottom: 1px solid #{$color-black};
      }
    }
    &__view-title {
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 10px;
      .service-sku-price {
        float: #{$rdirection};
        font-weight: normal;
      }
    }
    &__view-message {
      float: #{$ldirection};
      width: 65%;
      margin-top: 5px;
      @include breakpoint($landscape-up) {
        width: 40%;
        margin-top: 0;
      }
      &-title {
        @include swap_direction(margin, 0 10px 10px 0);
        float: #{$ldirection};
      }
      &-preview {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.3;
        margin-bottom: 5px;
      }
      &-bottom {
        margin-top: 15px;
        float: #{$ldirection};
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 100%;
        }
        .service-sku-price {
          float: #{$rdirection};
          margin-top: 10px;
        }
        .type {
          font-size: 20px;
          margin-bottom: 18px;
          text-transform: capitalize;
        }
        & ~ p {
          @include swap_direction(margin, 15px 0);
          float: #{$ldirection};
        }
      }
    }
    &__message-text {
      margin-top: 15px;
      display: inline-block;
      span {
        font-weight: bold;
      }
    }
    &-copy {
      margin-bottom: 7px;
      display: block;
    }
    &__placement {
      margin-bottom: 7px;
    }
    &__overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      #{$rdirection}: 0;
      bottom: 0;
      #{$ldirection}: 0;
      background: #{$color-gray-transparent};
      display: none;
      z-index: 999;
      .popup {
        @include swap_direction(margin, 100px auto);
        width: 370px;
        background: #{$color-white};
        @include breakpoint($landscape-up) {
          width: 443px;
        }
        &__zoom-container {
          padding: 20px;
          h3 {
            font-size: 20px;
            text-transform: uppercase;
            line-height: 1;
            float: #{$rdirection};
            span {
              float: #{$rdirection};
              width: 20px;
              height: 20px;
              cursor: pointer;
              line-height: 0.3;
              font-size: 45px;
            }
            &:after {
              content: '';
              display: table;
              clear: both;
            }
          }
          .engraving__image-notification {
            display: inline-block;
            font-size: 17px;
            margin-top: 0;
          }
          .engraving-zoom-save {
            display: inline-block;
            margin-top: 10px;
            background: #{$color-black};
            color: #{$color-white};
            width: 100%;
            @include breakpoint($landscape-up) {
              display: none;
            }
          }
          .popup_edit {
            width: 100%;
            display: inline-block;
            border: 1px solid #{$color-black};
            margin-top: 10px;
            background: #{$color-white};
            color: #{color-black};
            @include breakpoint($landscape-up) {
              display: none;
            }
          }
        }
        &__zoom-content {
          width: 330px;
          height: 370px;
          position: relative;
          background: #{$color-white};
          border: 1px solid #{$color-black};
          @include breakpoint($landscape-up) {
            width: 400px;
            height: 400px;
          }
          .engraving__live-preview-text {
            font-size: 16px;
            line-height: 1;
            top: 155px;
            letter-spacing: 2px;
            #{$rdirection}: 22px;
            @include breakpoint($landscape-up) {
              font-size: 17px;
              top: 192px;
              #{$ldirection}: 23.5%;
            }
            &.engraving__black-brush {
              @include breakpoint($landscape-up) {
                #{$ldirection}: 5.5%;
              }
            }
            &.engraving__view-multiline {
              top: 175px;
            }
            &-line {
              @include breakpoint($landscape-up) {
                margin-#{$ldirection}: 15px;
              }
            }
          }
        }
      }
    }
    &.engraving-edit-open {
      margin-top: 0;
    }
  }
}

.checkout {
  &__sidebar {
    &.right {
      .cart-item {
        .engraving__view-message {
          &-preview {
            font-family: #{$bb-sans};
            font-size: 16px;
          }
          &-position {
            margin-top: 10px;
          }
        }
        .product__price--engraving {
          float: #{$rdirection};
        }
      }
    }
  }
}

#cboxLoadedContent {
  .engraving {
    background-color: #{$color-white};
    text-align: center;
    &__about-engraving-popup {
      margin-top: 30px;
      &-title {
        font-size: 55px;
        font-weight: bold;
        line-height: 1;
        @include breakpoint($landscape-up) {
          font-size: 60px;
        }
      }
      &-content {
        font-size: 18px;
        margin-top: 35px;
        line-height: 1.3;
      }
    }
  }
}

.engraving {
  &__choice-bbsans {
    font-family: #{$bb-sans};
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .cart-products {
      .prod {
        .price {
          float: none;
        }
      }
      .engraving {
        &__service-sku-price {
          padding-top: 35px;
          float: #{$rdirection};
          font-family: #{$brandon-text};
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
  .kit-container {
    width: 59%;
    margin-top: 10px;
    .shade-info {
      text-transform: none;
      font-size: 14px;
      line-height: 1;
      text-align: #{$ldirection};
      letter-spacing: 1px;
      @include breakpoint($medium-up) {
        font-size: 16px;
      }
      &__label {
        letter-spacing: 1px;
        color: #{$color-black-2};
        &:after {
          content: ': ';
        }
      }
    }
    .engraving {
      &__engraved-text {
        margin-top: 10px;
      }
    }
  }
}

.checkout__sidebar {
  .checkout__header-item-count {
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 0;
    }
    @include breakpoint($landscape-up) {
      margin-#{$ldirection}: 30px;
    }
  }
  #viewcart-panel {
    .viewcart-panel__content {
      .cart-items {
        .cart-item {
          &__desc-container {
            .qty {
              float: #{$rdirection};
              top: 40px;
            }
            .total {
              &.cart-item__total {
                color: #{$color-black};
              }
            }
            .desc {
              &.cart-item__desc {
                width: 70%;
              }
            }
          }
        }
      }
    }
  }
}

#order-summary-panel {
  .total {
    .close-link {
      display: none;
    }
  }
}

.kit-container {
  .engraved {
    &_message {
      @include swap_direction(margin, 15px 0 0 100px);
      border-top: 1px solid #{$color-gray-lighter};
    }
    &_position {
      float: #{$ldirection};
      margin-top: 15px;
    }
    &_service-sku-price {
      float: #{$rdirection};
      margin-top: 15px;
    }
  }
}
