body.domain-uk {
  .product-thumb--price {
    border-bottom: 0;
    height: auto;
    line-height: 1;
    margin: 10px 0 0;
    padding: 10px 0;
  }
  .product-thumb--ppu,
  .product__ppu {
    font-family: 'Brandon Text Bold';
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
  .product-thumb--ppu {
    border-bottom: 1px solid #000;
    height: auto;
    line-height: 1;
    margin: 0 0 10px;
    padding: 0 0 10px;
    &:empty {
      padding: 0;
    }
  }
  .product__ppu {
    float: none;
    text-align: center;
  }
  .spp__works-with {
    padding-top: 1em;
  }
}

@media (max-width: 767px) {
  body.domain-uk {
    .product--full .product__header .product__rating {
      width: auto;
      .BVRRRatingSummaryLinkWriteFirst a {
        display: inline;
        text-decoration: underline;
        color: #ff4661;
      }
    }
  }
}
