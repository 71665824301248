.checkout__content {
  .registration-panel {
    //padding-bottom: 38px;
    &__title {
      @include checkout-section-header;
    }
    .checkout__panel-content {
      @include breakpoint($medium-up) {
        padding-left: 20px;
      }
    }
    .create_account_content {
      @include clearfix;
      border-bottom: 1px solid $color-gray-border;
      margin-bottom: 25px;
      padding-bottom: 10px;
    }
    .how--we-will-use-container {
      .field-are-required {
        @include breakpoint($medium-up) {
          text-align: right;
        }
      }
      @include breakpoint($medium-up) {
        float: right;
        width: 51%;
        margin-right: 20px;
      }
      .how-will-we-use {
        @include breakpoint($medium-up) {
          border: 1px solid $color-gray-border;
          padding: 10px;
          margin-bottom: 10px;
        }
      }
      h3 {
        @include breakpoint($medium-up) {
          @include h24;
          margin-bottom: 15px;
        }
      }
    }
    .registration-form-container {
      @include breakpoint($medium-up) {
        float: left;
        width: 35%;
      }
      .password-field {
        .password-field {
          &__info {
            &::before {
              top: 36%;
              right: 85%;
            }
          }
          &__rules {
            @include breakpoint(($medium-up $landscape-up) (orientation portrait)) {
              padding-left: 10px;
            }
          }
        }
      }
    }
    .checkout-registration__email-address-title {
      @include h24;
      display: block;
    }
    .checkout-registration__email-address {
      margin-bottom: 15px;
      br {
        @include hidden;
      } //oh 1page...
    }
    .checkout-registration__terms-title {
      clear: both;
      padding-top: 36px;
      margin-bottom: 26px;
    }
    .checkout-registration__password {
      .label {
        @include h24;
        display: block;
        margin-bottom: 15px;
      }
      .note {
        @include hidden;
      }
    }
    .password-hint {
      .note {
        @include hidden;
      }
    }
    .divide {
      clear: both;
    }
    .accepted-privacy-policy {
      .terms_disclaimer {
        @include hidden; //no rb key setup
      }
    }
    .continue-button-wrapper {
      margin-top: 38px;
      text-align: right;
    }
    .field-are-required {
      @include breakpoint($small-down) {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    #checkout_registration {
      margin-left: 20px;
    }
  }
  .shipping-panel {
    &#shipping-panel {
      display: block;
    }
    @include checkout-label-and-checkbox-form-item;
    &__tittle {
      @include breakpoint($small-down) {
        line-height: 18px;
      }
    }
    .checkout__subtitle {
      @include breakpoint($medium-up) {
        border-top: none;
        padding-top: 26px;
        margin-top: 13px;
        width: 575px;
      }
      @include breakpoint($small-down) {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 10px;
        &.checkout_header_search_address {
          padding-bottom: 0;
        }
      }
    }
    .new-address {
      @include breakpoint($small-down) {
        .form-item {
          margin-bottom: 5px;
          &.title {
            width: 50%;
          }
          &.qas-submit-container {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
      @include breakpoint($medium-up) {
        width: 379px;
        .form-item {
          &.title,
          &.first-name,
          &.last-name,
          &.phone-number,
          &.building-name-number,
          &.postal-code,
          &.address1,
          &.address2,
          &.city,
          &.county,
          &.postal-code,
          &.country-id,
          &.delivery {
            width: 49%;
            margin: 0;
          }
          &.building-name-number {
            float: left;
            margin-bottom: 5px;
          }
          &.postal-code {
            float: right;
            margin-bottom: 5px;
          }
          input {
            margin-bottom: 15px;
          }
          label {
            margin: 14px 0;
            letter-spacing: 0.2em;
          }
        }
        #manual-address-fields {
          .form-item {
            &.address1,
            &.city,
            &.postal-code {
              float: left;
            }
            &.address2,
            &.county,
            &.country-id {
              float: right;
            }
            &.country-id {
              height: 94px;
              label {
                margin-bottom: 22px;
              }
            }
          }
        }
      }
    }
    .panel-group {
      @include clearfix;
      @include breakpoint($small-down) {
        margin: 5px 10px 0; // left/right margin is "standard" for mobile's checkout section, for UK
      }
    }
  }
  .shipping-edit-address-content__content {
    .personal-details {
      .form-item {
        @include breakpoint($medium-up) {
          &.title,
          &.phone-1 {
            float: none;
          }
        }
      }
    }
  }
  .gift-options__content {
    .form-item input,
    textarea {
      margin-top: 18px;
    }
    @include breakpoint($medium-up) {
      margin: $checkout-spacing 0 5px 0;
    }
  }
  .gift-msg {
    &__title {
      @include h24;
      margin: 30px 0 20px 0;
    }
    &__head {
      width: 379px;
      @include clearfix;
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
    &__to {
      @include checkout-item-column;
      @include breakpoint($medium-up) {
        width: 48%;
      }
    }
    &__from {
      @include checkout-item-column;
      @include breakpoint($medium-up) {
        width: 48%;
        margin-left: 15px;
      }
    }
  }
  #continue-btn {
    text-align: right;
    margin: $checkout-spacing 0 0 0;
  }
  #review-panel {
    display: block;
  }
}

.shipping-edit-content {
  #checkout_shipping_panel {
    @include breakpoint($small-down) {
      margin: 0 10px;
    }
  }
}

.shipping-address-display,
.gift-options-display {
  float: $ldirection;
  margin-#{$ldirection}: 20px;
  @include breakpoint($small-down) {
    margin: 0;
    &:first-child {
      margin-right: 2%;
    }
    .checkout__subtitle {
      @include h8;
      .checkout__content & {
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid $color-black;
      }
    }
  }
}

.shipping {
  &-panel {
    .checkout__subtitle {
      .checkout__content & {
        @include breakpoint($medium-up) {
          padding-bottom: 0;
        }
      }
    }
    #qas .checkout__subtitle {
      margin-top: 12px;
    }
  }
  &-edit-address-content {
    &__content {
      @include breakpoint($small-down) {
        margin-top: 5px;
      }
      .field-are-required {
        @include breakpoint($small-down) {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
      label {
        display: inline-block;
      }
      .new-address {
        label {
          display: block;
          @include breakpoint($medium-up) {
            @include h12;
            margin: 15px 0;
          }
        }
      }
      .qas-submit-container {
        margin-bottom: 15px;
        @include breakpoint($small-down) {
          input[type='button'] {
            background-color: $color-pink;
          }
        }
      }
      label {
        @include breakpoint($medium-up) {
          display: block;
        }
      }
      .address-to-use {
        @include breakpoint($small-down) {
          padding-left: 0;
          padding-right: 0;
        }
        &-option {
          display: inline-block;
          @include breakpoint($medium-up) {
            margin: 0 30px 20px 0;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

#shipping-panel {
  &.substate-manual {
    .manual-address {
      display: none;
    }
    #manual-address-fields {
      // override default hidden state of sub-panel
      display: block !important;
      visibility: visible;
    }
  }
}

.QAS_SUBMIT {
  @include input-button--secondary;
}
