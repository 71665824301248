/* Search */
.section-esearch {
  .search-form {
    &__results-count {
      padding: 7px 17px;
    }
    &__submit {
      margin: 0 !important;
    }
  }
  #search-wrapper {
    #col-1,
    #summary,
    .results-summary,
    .results-header {
      display: block;
    }
  }
  #results-container {
    #col-1 {
      display: none;
    }
    #col-2 {
      width: 100%;
      #product-results {
        .results-header {
          .product-sort-container {
            display: none;
          }
        }
      }
      #featured-products {
        float: left;
        width: 100%;
      }
    }
  }
  #bestseller-results {
    border-top: none;
    padding-top: 0;
    .results-header {
      border-top: 1px solid #d0d0d0;
      padding-top: 40px;
      .product-sort-container {
        display: none;
      }
    }
  }
}

.section-esearch {
  #search-wrapper {
    .summary-chat-now {
      display: none;
    }
    .summary-search-form {
      display: block;
      margin-bottom: 30px;
    }
    #results-container {
      #col-2 {
        #product-results {
          margin-left: 30px;
        }
      }
      #col-1 {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 2px;
        font-family: 'Brandon Text Bold';
        #breadcrumbs,
        #dimensions {
          margin-bottom: 20px;
        }
        #dimensions,
        #breadcrumbs,
        #recent-searches {
          h3 {
            text-transform: uppercase;
            padding: 10px 0px;
            margin: 0px 10px;
            font-weight: bold;
            margin-bottom: 0px;
            border-top: none;
            border-right: none;
            border-left: none;
            border-bottom: 1px solid #bfbfbf;
          }
          dl {
            margin-left: 10px;
            dd {
              margin-left: 0px;
              padding-bottom: 3px;
              .close {
                margin-left: 5px;
              }
              a {
                text-decoration: none;
                text-transform: uppercase;
                font-weight: bold;
              }
            }
            dt {
              cursor: pointer;
              margin-bottom: 5px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service-contact-us {
    #contact-about-content {
      .customer-service-contact-form__category {
        padding: 1em 0;
        letter-spacing: 1px;
      }
    }
  }
}

.spp {
  .responsive-tabs--how_to_use-wrap {
    .resp-tab-item {
      padding: 8px 15px !important;
    }
  }
}

.responsive-tabs {
  .resp-tabs-list {
    .bv_ask_tab {
      display: none;
    }
  }
}

.checkout {
  .checkout__content {
    #review-panel {
      .review-edit-content {
        .sub-section.address {
          .set-address {
            .select-address {
              .new-address {
                .form-item.title {
                  margin-bottom: 15px;
                }
              }
              .existing-address {
                .select-menu {
                  a.selectBox {
                    border: 1px solid;
                    &:hover {
                      border-color: #000;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    #shipping-panel {
      .shipping-edit-content {
        #checkout_shipping_panel {
          h3.shipping_address_header {
            border-bottom: 1px solid #000;
            padding: 10px 0px 10px 0px;
            margin-bottom: 15px;
            span.title {
              font-weight: bold;
              text-transform: uppercase;
            }
            span.required {
              float: right;
              font-size: 10px;
            }
          }
          .select-address {
            .address-to-use {
              overflow: hidden;
              span.address-to-use-option {
                @include address-alignment();
                text-transform: uppercase;
                width: auto;
              }
            }
            .select-menu {
              width: 60%;
            }
            .local-collection {
              .personal-details {
                overflow: hidden;
                .form-item {
                  @include address-alignment();
                  label {
                    margin-bottom: 5px;
                    letter-spacing: 0.15em;
                    text-transform: uppercase;
                    display: block;
                  }
                  input {
                    margin-bottom: 0px;
                  }
                  &.first-name {
                    clear: both;
                  }
                }
                .set-collection-point {
                  h3 {
                    border-bottom: 1px solid #000;
                    padding: 10px 0px 10px 0px;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                    font-weight: bold;
                  }
                  .form-item {
                    width: auto;
                    a {
                      background-color: #ff4661;
                    }
                  }
                }
              }
            }
            .new-address-container {
              margin-top: 15px;
              overflow: hidden;
              width: auto;
              .form-item {
                @include address-alignment();
                &.first-name {
                  clear: both;
                }
                input {
                  margin: 0px;
                }
              }
              #manual-address-fields {
                clear: both;
                .delivery {
                  width: 85%;
                }
                .country-id {
                  height: auto;
                }
                .default-shipping {
                  width: auto;
                  margin: 0px;
                }
              }
              &.add {
                margin-top: 0%;
                #qas {
                  clear: both;
                  overflow: hidden;
                  h3.checkout__subtitle {
                    margin: 0px 0px 15px 0px;
                    padding: 20px 0px 10px 0px;
                    border-top: 1px solid #d4d4d4;
                  }
                  .qas-submit-container {
                    input {
                      background-color: #ff4661;
                    }
                  }
                  .manual-address {
                    width: 100%;
                    margin: 0px 0px 20px 0px;
                    border-bottom: 1px solid #d4d4d4;
                    padding-bottom: 21px;
                  }
                }
              }
            }
          }
          .gift-options__content {
            margin: 0px 0px 25px 0px;
            h3.checkout__subtitle {
              padding: 0px 0px 10px 0px;
            }
          }
          .email-sms-promotions {
            .promotions-container {
              h3.checkout__subtitle {
                padding: 0px 0px 10px 0px;
              }
              p {
                margin: 0px 0px 15px 0px;
              }
              .fs email_promotions {
                padding-bottom: 15px;
                border-bottom: 1px solid #d4d4d4;
              }
            }
          }
        }
      }
      .panel-group {
        #shipping-address-display,
        #gift-options-display {
          h3.checkout__subtitle {
            width: auto;
            padding: 0px 0px 10px 0px;
            margin: 0px 0px 10px 0px;
          }
        }
        .gift-options-display {
          p {
            margin-bottom: 15px;
          }
        }
      }
    }
    .viewcart-panel {
      .viewcart-panel__content {
        .cart-items {
          .cart-item {
            .cart-item__desc-container {
              .cart-item__qty {
                width: 25%;
              }
              .cart-item__total {
                width: 13%;
              }
            }
            .cart-item__thumb {
              img {
                width: auto;
              }
            }
          }
        }
      }
    }
    .registration-panel {
      margin-bottom: 15px;
      .content {
        margin-top: 10px;
        #checkout_registration {
          .create_account_content {
            .registration-form-container {
              .email-address {
                font-size: 12px;
                span.title {
                  font-weight: bold;
                  text-transform: uppercase;
                }
                span.example-user-email {
                  color: #fc8d9e;
                }
              }
              .password {
                font-size: 12px;
                label {
                  font-weight: bold;
                  text-transform: uppercase;
                  margin-bottom: 15px;
                  display: block;
                }
              }
            }
          }
          .continue-button-wrapper {
            margin-bottom: 15px;
          }
        }
      }
    }
    .review-panel {
      .review-edit-content {
        h3.checkout__subtitle {
          span.field-are-required {
            float: right;
            font-size: 10px;
            text-transform: lowercase;
            font-weight: bold;
          }
        }
        .sub-section.address {
          .set-address {
            .select-address {
              .address-to-use {
                margin: 5px 0px 10px 0px;
                span {
                  margin: 0px 10px 0px 0px;
                }
              }
              .new-address {
                .form-item {
                  @include address-alignment();
                  &.first-name {
                    clear: both;
                  }
                  input {
                    margin: 0px;
                  }
                }
                #qas {
                  clear: both;
                  h3.checkout__subtitle {
                    margin: 0px 0px 15px 0px;
                    padding: 20px 0px 10px 0px;
                    border-top: 1px solid #d4d4d4;
                  }
                  .qas-submit-container {
                    input {
                      background-color: #ff4661;
                    }
                  }
                }
              }
              .form-item {
                a.selectBox {
                  span.selectBox-arrow {
                    height: 98%;
                  }
                }
              }
            }
          }
        }
        .payment_method-container {
          .form-container {
            width: 45%;
          }
          #checkout_payment {
            width: auto;
            .form-item {
              margin-bottom: 20px;
              label {
                font-weight: bold;
                margin-bottom: 10px;
                display: block;
              }
              input {
                margin: 0px;
              }
              &.card-cvn {
                float: none;
                width: auto;
                margin-left: 0px;
                input {
                  width: 30%;
                  float: left;
                  margin-right: 20px;
                }
              }
              &.card-type {
                width: 65%;
              }
              &.checkbox {
                label {
                  font-weight: normal;
                }
              }
            }
          }
        }
        .hidden_nojs {
          #checkout_review,
          #checkout_reviewGiftCardOnly {
            section.divide {
              margin-bottom: 20px;
              p {
                margin-bottom: 15px;
              }
            }
          }
        }
        #checkout_billing_panel {
          .form-container {
            .sub-section {
              margin-bottom: 20px;
              .form-item {
                span {
                  margin-right: 10px;
                }
              }
            }
            .payment-type {
              span.payment-option {
                float: left;
              }
            }
          }
        }
        #checkout_saved_payment {
          p {
            margin-bottom: 15px;
          }
          h3 {
            font-weight: bold;
          }
          .form-item {
            label {
              font-weight: bold;
              margin-bottom: 15px;
              display: block;
            }
            input {
              width: 30%;
              float: left;
              margin-right: 20px;
            }
          }
        }
      }
    }
    #recommended-products-panel {
      h2.checkout__panel-title {
        text-align: left;
      }
      h2.checkout__panel-title:before,
      .checkout__panel-title:after {
        border-top: none;
        width: 5px;
        margin: 0 0.4em 0 0.8em;
      }
      .content {
        .recommended-product-items {
          .recommended-item {
            width: 30%;
            .description {
              margin-top: 15px;
              .info {
                .formatted_price {
                  border: none;
                  text-align: left;
                }
                div {
                  padding: 0.4em 0;
                }
              }
            }
            .quickshop {
              a.recommended-item__button {
                color: #{$color-bundle-gray};
                background-color: #fff;
                padding: 0px 0px 10px 0px;
                text-align: left;
                letter-spacing: 1px;
                font-size: 12px;
                border-bottom: 1px solid;
              }
            }
            .addtobag {
              margin-top: 10px;
              #cart {
                input.btn {
                  background-color: #fff;
                  color: #{$color-bundle-gray};
                  font-size: 12px;
                  letter-spacing: 1px;
                  border-bottom: 1px solid;
                  padding: 0px;
                }
              }
            }
          }
        }
      }
    }
    .sign-in-panel {
      .panel-group {
        .new-account,
        #return-user {
          form {
            .form-item {
              .example {
                span em {
                  color: #fc5669;
                }
              }
            }
          }
          .adpl {
            .form-item {
              .return-user-email {
                width: 100%;
              }
              .label {
                line-height: 1.8em !important;
                margin-top: calc(-4em - 1px);
              }
            }
          }
        }
      }
    }
  }
}

.responsive-tabs {
  #BVRRContainer {
    .BVRRSortAndSearch {
      float: left;
    }
  }
}

.BVPageBody {
  #BVFieldContextdatavalueAgeContainerID {
    float: left;
  }
  #BVFieldContextdatavalueUsedProductContainerID {
    float: left;
  }
  #BVSectionNetPromoterID {
    clear: both;
  }
}

.quickshop__container {
  .product--full {
    .product__rating {
      div.t-desktop {
        display: none;
      }
      span {
        div.t-desktop {
          display: inline;
        }
      }
    }
  }
}

.site-utils__right {
  ul.site-utils__menu {
    .js-open-lc-pre_chat.js-open-lc-pre_chat-processed {
      display: none;
    }
  }
}

.spp {
  .product {
    .product__details {
      .product__header {
        .product__rating {
          padding: 0 0 18px;
        }
      }
    }
  }
}

.section-esearch {
  #search-wrapper {
    margin-top: 40px;
    #col-2 {
      #product-results {
        border: none;
        .quickshop-wrapper.active--2::before {
          left: 50%;
        }
        .product-grid__item {
          width: 33.33%;
        }
        .quickshop-wrapper {
          display: none;
        }
        .results-summary {
          display: none;
        }
        .results-header {
          h3 {
            text-indent: 0;
          }
          #summary {
            text-align: left;
            font-family: 'Brandon Text Bold';
            font-size: 14px;
            letter-spacing: 0.24em;
            line-height: 19px;
            text-transform: uppercase;
          }
        }
      }
    }
    .pagination-container {
      margin-bottom: 20px;
      .pagination {
        .view-all.first > a {
          @include typography-label-text;
          border-bottom: 1px solid $color-core-black;
          font-size: 14px;
        }
        .page {
          display: none;
        }
        .next.last {
          display: none;
        }
      }
    }
  }
}

.order-details-page {
  .order-details-page__content {
    .order-details {
      .gift_container {
        .gift_message {
          span {
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

.customer-service-section {
  h2 {
    padding: 1.5em 18px;
  }
  border-bottom: 1px solid #ccc;
  .customer-service-landing-block__link-container {
    text-align: center;
    display: block;
  }
  .customer-service-landing-block__link {
    width: 100px;
    position: inherit;
    border-width: 3px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin: 0 auto;
  }
  #landing-chat {
    .customer-service-landing-block {
      &__link-container {
        div {
          display: block;
        }
      }
      &__link {
        bottom: 19px;
      }
    }
  }
  .customer-service-landing-block-call {
    display: block !important;
    background: none !important;
    margin: 0;
    padding: 50px 130px 0px 130px;
    text-align: center;
    border-top: 1px solid #ccc;
    clear: both;
    h2 {
      background-image: none;
      border-top: 0px;
    }
    span {
      font-size: 3em;
    }
    .customer-service-landing-block__subheader {
      font-weight: bold;
      margin-bottom: 6px;
    }
    .customer-service-landing-block__phone-number {
      color: #ff4661;
      letter-spacing: 5px;
    }
    .customer-service-landing-block__desc {
      margin-bottom: 15px;
      .customer-service-landing-block__phone-no {
        color: #ff4661;
        font-size: 14px;
      }
    }
  }
  .active-subsection {
    .address-form {
      .button-loading {
        width: 23%;
        margin: 7px 0 7px 0;
        line-height: 0.7;
        height: 30px;
      }
    }
  }
}

.offers-details {
  .offers-details-item__actions {
    .offers-details-item__link {
      letter-spacing: 0.15em;
      font-size: 13px;
    }
  }
}

.section-customer-service {
  .offers-details-items {
    margin: 0px 0px 50px 310px;
  }
}

.order-details-page__content {
  .order-products {
    .cart-item {
      .cart-item__desc {
        width: 35%;
        float: left;
      }
      .cart-item__price {
        width: 20%;
      }
      .cart-item__total {
        text-align: right;
        float: right;
      }
      @media only screen and (max-width: 768px) {
        .cart-item__desc {
          width: 29%;
        }
      }
    }
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__link-events {
      float: right;
      margin: 5px 0 0 20px;
    }
  }
}

.product__flag {
  display: initial;
}

#review-panel {
  .review-edit-content {
    .sub-section.address {
      .set-address {
        .select-address {
          .new-address.add {
            #manual-address-fields {
              .country-id-select {
                clear: both;
                a {
                  border: 1px solid;
                }
                label {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
    .hidden_nojs {
      #checkout_review {
        section.divide {
          p {
            margin-bottom: 15px;
            &.newsletter-info__text--provid {
              margin: 15px 0;
            }
          }
        }
      }
    }
  }
  .adpl {
    .expires-date__container {
      .expires-date__month {
        width: 40%;
      }
      .expires-date__year {
        float: right;
        width: 40%;
        margin: -12% 0 0 0;
      }
    }
    .form-item.card-cvn {
      margin: -10% 0 0 0;
      input {
        width: 54% !important;
        float: none !important;
        margin-top: 20px !important;
      }
      #security-code {
        float: right;
        margin: -14% 8% 0 0;
      }
    }
    .form-item {
      .cv_number_field {
        float: none !important;
      }
      #security-code {
        float: right;
        margin: -10% 51% 0 0;
      }
      .selectbox,
      a.selectBox,
      .selectBox-options a {
        padding: 0 0 0 7px !important;
      }
    }
    .form-item.card-type,
    .form-item.card-expiry-date {
      .label-content {
        display: none;
      }
    }
    .gift-card-entry-container {
      margin: 10px 0 0 0;
      .form-item.gift-card-number,
      .form-item.gift-card-pin {
        label {
          line-height: 1em !important;
          margin-top: calc(-3.5em - 1px) !important;
        }
      }
    }
  }
  .address.sub-section {
    .set-address {
      .select-address {
        .new-address {
          .form-item.title {
            float: none !important;
          }
        }
      }
    }
  }
  #checkout_saved_payment {
    .form-item {
      label {
        line-height: 1em !important;
      }
    }
  }
}

#confirmation-page {
  #sign-in-confirmation-panel {
    .sign-in-confirmation-panel__content {
      #completed_transaction_signin {
        .email-address {
          label {
            border-bottom: 0px;
            padding: 0px;
            margin: 0px;
            text-transform: none;
            font-family: 'Brandon Text';
          }
        }
      }
    }
  }
  #confirmation-panel {
    .confirmation-panel__content {
      .confirmation-panel__confirm-text--donation {
        .checkout__subtitle {
          border-bottom: 0px;
          margin-bottom: 0px;
        }
        margin-left: -20px;
        margin-bottom: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        background-color: #f0f0f0;
      }
    }
  }
}

// ie8 related CSs
.ie8 {
  .mpp {
    .mpp__content .product-grid--quickshop {
      .product-grid__content .quickshop-wrapper {
        .quickshop .product__details {
          padding: 22px;
        }
      }
    }
  }
  #col-2 {
    .product-grid.js-product-grid {
      .product-thumb {
        a.product-thumb__image-link div {
          display: block;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .section-esearch {
    .quickshop__container {
      .product__actions {
        .product__button {
          float: left;
          margin-top: 15px;
        }
        ul.product__inventory-status {
          clear: both;
        }
      }
    }
  }
}

#wrapper {
  .beauty-consultations {
    .beauty-consultations-instructions {
      a.create_button {
        border: 1px solid #ff4661;
        height: 36px;
        background-color: white;
        padding: 10px 6px 0 6px;
        letter-spacing: 2.5px;
        text-decoration: none;
        color: #ff4661;
      }
    }
  }
}

#custom-container {
  .brand-topband {
    position: absolute;
    min-width: 100%;
    width: 100%;
  }
  #user-input-container {
    top: 0px;
    position: inherit;
    #lp-dashboard-btn-container {
      position: absolute;
    }
    .chat-window-container {
      overflow-y: hidden !important;
      margin-top: 96px;
      height: 485px;
      #chatContainer {
        border-right: 1px solid lightgray;
        #chatLines {
          .rich-line-content {
            .txt-line {
              width: 410px;
              float: none;
            }
          }
          .no-icon-line {
            font-size: 12.5px !important;
            font-family: 'Brandon Text';
            font-weight: normal !important;
            line-height: 19px;
            padding: 5px 20px 0px 5px;
          }
        }
      }
    }
  }
  .text-input-container {
    border-top: 1px solid lightgray;
    width: 476px;
    .send-btn {
      border: none;
    }
  }
  .right-col-top {
    top: 100px;
  }
  .right-col-bottom {
    #beauty-consultant-panel {
      .section-heading-consultant {
        margin-top: 50px;
      }
    }
    #beauty-consultant-panel-integrated {
      .section-heading-consultant {
        margin: 45px 12px 15px 28px;
      }
    }
  }
  #product-recommendations-panel {
    .section-heading-consultant {
      margin-top: 50px;
    }
    .product-sku-panel {
      padding-top: 0px;
      .carousel-content-container {
        .detail-col {
          .favorites-hitarea {
            .icon {
              color: $color-pink;
              top: 25px;
              position: relative;
              cursor: pointer;
              font-size: 18px;
              float: left;
              right: 30px;
            }
            .icon-heart {
              &_outline {
                color: $color-black;
              }
              &_outline:hover {
                color: $color-pink;
              }
            }
          }
          .add-to-bag-btn-container {
            margin-top: 5px;
            .msg-addToBag {
              margin-top: 10px;
              margin-left: 1px;
            }
          }
          .prod-title {
            cursor: pointer;
            white-space: normal;
            height: 42px;
          }
          .shade-container {
            height: 22px;
          }
        }
      }
    }
  }
}

#shipping-panel {
  .adpl {
    .select-address {
      .new-address {
        .form-item {
          label {
            margin-top: calc(-3em - 1px) !important;
          }
        }
      }
    }
    .gift-options {
      .gift-options {
        .sub-section {
          .gift-msg__head {
            .form-item {
              label {
                line-height: 1em;
                margin-top: calc(-3.5em - 1px);
              }
            }
          }
        }
      }
    }
    .address-to-use {
      label {
        display: inline-block;
      }
    }
    .form-item.default-shipping {
      margin: 14px 0 0 0 !important;
    }
    .form-item {
      .sms_promo_label::before {
        top: 6px !important;
      }
    }
  }
}

#review-panel,
#shipping-panel,
#registration-panel {
  .adpl {
    .sms-promo-wrapper {
      .form-item {
        .sms_input {
          width: 50%;
          margin-top: 13px;
        }
        .sms_label {
          line-height: 1em;
          margin-top: calc(-3.5em - 1px);
          padding-left: 61px;
        }
      }
    }
  }
}

#offer-code-panel {
  .adpl {
    input[type='text'] {
      height: 2.4em;
    }
  }
}

#registration-panel {
  .adpl {
    .create_account_content {
      .registration-form-container {
        .password {
          label {
            line-height: 0.7em;
            margin-top: calc(-3.6em - 1px);
          }
        }
      }
    }
  }
}

#confirmation-page {
  #registration-panel {
    .adpl {
      .create_account_content {
        .registration-form-container {
          .password {
            label {
              line-height: 0.8em;
              margin-top: calc(-4.1em - 1px);
            }
          }
        }
      }
    }
  }
}

.panel select.error,
.panel select.checkedEmpty,
.panel select.touched,
.panel a.selectbox.checkedEmpty {
  border-color: red !important;
}

.panel label.label-content.checkedEmpty {
  color: red;
}

.site-footer {
  .sticky-chat {
    a {
      .sticky-chat__copy {
        height: auto;
      }
    }
  }
  .tooltiptext-under {
    a {
      display: inline-block;
    }
  }
}

.ecommerce {
  .site-content {
    .live-chat-landing {
      .text-block_link {
        .LPMcontainer {
          position: inherit !important;
        }
      }
    }
  }
}

.lp_desktop {
  #lpChat {
    input[type='radio'] {
      position: absolute;
    }
    .lp_maximized {
      right: 211px !important;
    }
  }
}
/* Added for foundation finder footer issue */
.skin-foundation-collection {
  .block_footer_links {
    .homepage_basic {
      a {
        position: static;
        width: 100%;
      }
      .homepage_basic_dek {
        width: 93%;
      }
    }
  }
}
/* enlarged mpp tiles in ie browser */
.product-grid__item--promo {
  .product-promo {
    .product-promo__content-middle {
      img {
        width: 100%;
      }
    }
  }
}

.site-nav {
  .menu__list--lvl-1 {
    .block-template-esearch-nav-v1 {
      @include breakpoint($medium-up) {
        display: block !important;
        padding: 0 !important;
      }
    }
  }
}

.video-carousel__block {
  .video-block__media--video {
    max-height: unset;
  }
}

.site-footer {
  .site-footer-contact {
    .talk_artist_text {
      display: inline-block;
      span {
        letter-spacing: 0.2em;
      }
    }
  }
  .sticky-chat {
    .sticky-chat__copy {
      height: auto;
    }
    p {
      margin-bottom: 0;
    }
  }
  .site-footer__wrap {
    .sticky-back-to-top-v2 {
      &.sticky-back-to-top-new {
        bottom: 120px;
        @include breakpoint($medium-up) {
          bottom: 50px;
        }
      }
    }
    .sticky-footer-chat {
      &.sticky-footer-new-chat {
        .sticky-footer-chat__link {
          bottom: 185px;
          width: 75px;
          @include breakpoint($medium-up) {
            bottom: 160px;
            width: 100px;
          }
        }
      }
    }
  }
}
/* Product Store Check */
.product--full {
  .product__actions {
    padding: 30px 0 30px;
  }
}

.product-full__store-check {
  .product-store-check--v2 {
    .instore-inventory-container {
      margin-top: 15px;
      float: inherit;
    }
    .product-store-check {
      &__inline--v2 {
        display: block;
        .inline-container {
          border-bottom: none;
          border-top: none;
          padding: 14px 0 0 0;
          @include breakpoint(($medium-only) (orientation portrait)) {
            width: 100%;
          }
          .inline-errors {
            @include typography-label-text;
            color: $color-red;
            font-size: 14px;
          }
          .edit-controls {
            display: block;
          }
          &__doors {
            .doors-container {
              .container-row__doors {
                .door-row {
                  .door-info__avail {
                    padding-#{$ldirection}: 1em;
                    .avail-info__msg {
                      width: 25%;
                      @include breakpoint($medium-landscape-only) {
                        width: 40%;
                      }
                      &--text {
                        font-size: 14px;
                      }
                      &--span {
                        @include swap_direction(margin, 0.15em 0.25em 0 0);
                        display: inline-block;
                        border-radius: 50%;
                        height: 0.75em;
                        width: 0.75em;
                        padding: 0.2em;
                        &.msg-span {
                          &__avail {
                            background-color: $color-green;
                          }
                          &__not-avail {
                            background-color: $color-red;
                          }
                          &__call {
                            background-color: $color-orange;
                          }
                        }
                      }
                    }
                    .door-info__address {
                      @include breakpoint($medium-landscape-only) {
                        width: 50%;
                      }
                      &--distance {
                        width: 20%;
                      }
                      &--name {
                        width: 30%;
                        padding-#{$rdirection}: 10px;
                      }
                      &--phone {
                        width: 30%;
                      }
                    }
                  }
                }
              }
            }
          }
          &__partner {
            @include typography-label-text;
            color: $color-core-black;
          }
          &__controls {
            padding: 10px;
            .avail-info__msg--span {
              display: none;
            }
          }
        }
      }
    }
    .location-controls {
      padding-left: 0;
      &__hdr {
        margin-#{$rdirection}: 5px;
      }
      &.edit-controls {
        .location-controls__hdr {
          width: 100%;
          border-bottom: 1px solid $color-gray-op15;
          height: 30px;
          margin-bottom: 10px;
        }
      }
      &__zip {
        margin-bottom: 10px;
        &--input {
          border: 1px solid $color-core-black;
          margin-#{$rdirection}: 5px;
          width: auto;
        }
      }
      &__btn--zip {
        height: 100%;
        line-height: 20px;
        padding: 9px 30px;
      }
      &__info {
        @include typography-label-text;
        margin-#{$rdirection}: 0;
      }
      &__link--edit {
        margin-#{$rdirection}: 5px;
      }
      &__distance--select {
        width: 100%;
        padding: 0 5px 0 0;
        position: relative;
        top: -5px;
        right: 0;
        @include breakpoint(($medium-only)) {
          @include swap_direction(padding, 0px 20px 0 2px);
        }
        @include breakpoint(($large-up)) {
          @include swap_direction(padding, 0px 5px 0 2px);
        }
      }
      &__close {
        color: $color-gray-dark;
        #{$rdirection}: 10px;
      }
    }
    .psc-state-init {
      .inline-container {
        &__partner {
          display: none;
        }
        &__link {
          display: block;
        }
      }
    }
  }
  .find-in-store {
    display: inline-block;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    border: 1px solid $color-black;
    padding: 5px 0;
    color: $color-black;
    text-decoration: none;
  }
}
// !important is used below to override the inline styles from 3rd party content
.spp-product-layout {
  .product-full {
    .afterpay-paragraph {
      margin-top: 5px;
      .afterpay-link {
        @include swap_direction(padding, 2px 3px 0 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #{$color-black};
        font-style: italic;
        font-weight: bold;
        text-align: center;
        font-size: 12px;
        color: #{$color-black} !important;
        line-height: 1;
        text-decoration: none !important;
      }
      .Clearpay-logo {
        vertical-align: sub !important;
        margin: 0 5px;
      }
    }
    &__data-price-size {
      .product-full__installment-price {
        text-align: #{$rdirection};
        width: 100%;
      }
    }
  }
  .product-full__container {
    .product-full__data-content--usage {
      a {
        color: $color-grey-scale;
      }
    }
  }
}
.spp-bundle-layout {
  &__get-set-cta {
    &.button--disabled {
      pointer-events: none;
    }
  }
  &__step {
    .bundle-product-slide {
      .shade-picker {
        &__outofstock {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}
.spp-olapic {
  .olapic {
    .olapic-slider-body {
      max-width: 980px;
      .olapic-slider-wrapper {
        max-width: 866px;
      }
    }
  }
}

.field-elc-mpp-content {
  .content-block__content {
    .content-block__line--content {
      a {
        color: $color-grey-scale;
      }
    }
  }
}

//Clearpay Popup styles override
#afterpay-modal-overlay {
  #afterpay-modal-modal {
    width: 70%;
    height: 75%;
  }
}
